@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Merienda+One&display=swap');
body {
  color: #565656;
  font-family: "Poppins", sans-serif;
}

a:hover {
    border: none !important;
    text-decoration: none !important;
}
.form-control:focus, input:focus, textarea:focus {
    box-shadow: 0px 0px 12px rgb(111 110 110 / 9%);
    border-color: #ced4da !important;
}
.form-control::placeholder, input::placeholder, textarea::placeholder{
    color: #ababab;
    font-size: 13px;
}
.display-in-mobile-only{
    display: none;
}
.top-header {
    width: 100%;    
    background: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    height: 73px;
    line-height: 73px;
    margin-bottom: 20px;
    box-shadow: 0 0 20px rgb(170 191 212 / 30%);
}

.elm-logo a{
    display: block;
}
.elm-logo .logo-for-mobile{
    display: none;
}
.top-background{
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 75px;
}
.user-profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    line-height: 0;
    position: absolute;
    padding: 6px;
    background: #FDFDFE;
    left: 0px;
    right: 0px;
    margin: auto;
    top: -67px;
    border: 1px dashed #ADC9E0;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px rgba(223, 228, 249, 0.5);
}

.user-profile-img img {
    border-radius: 50%;
}

.change-profile-picture {
    position: absolute;
    bottom: -2px;
    right: -6px;
    display: block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px dashed #ADC9E0;
    box-sizing: border-box;
    color: #EE9949;
}

.user-name {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    border-bottom: 0 solid #eee;
    padding-bottom: 6px;
    background: #fff;
    padding: 59px 12px 15px 12px;
    color: #1D458A;
    box-shadow: 0 10px 13px -5px rgb(223 228 249 / 55%);
    text-transform: capitalize;

}

.user-name a {
    font-size: 12px;
    color: #000;
    margin-left: 9px;
}

.help-settings {
    position: relative;
    float: right;
}

.help-settings a {
    font-size: 15px;
    margin-left: 7px;
    margin-right: 33px;
    color: #12274b;
}


/* =====end of top header======= */

.main-container {
    width: 90%;
    display: block;
    overflow: auto;
    margin: 25px auto 0px;
    max-width: 1500px;
    min-height: 800px;
}

.my-account-side-menu {
    width: 20%;
    float: left;
    padding: 17px;
    margin-top: 70px;
}

.my-account-right-section {
    width: 80%;
    float: left;
    padding: 17px;
}

.side-menu-account {
    list-style: none;
    padding: 11px;
    margin-top: 10px;
}

.side-menu-account li a {
    color: #12274b!important;
    font-size: 13px;
    display: block;
    height: 45px;
    margin-bottom: 10px;
    line-height: 45px;
    padding: 0 11px;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
}

.side-menu-account li a.active {
    color: #EE9949 !important;
}

.footer {
    background: #696969;
    color: #ffff;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

.section-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #ee9949;
    text-transform: uppercase;
    position: relative;
    height: 80px;
}
.section-header .blue-text{
    color: #2E3552;
}
.section-header p{
    background: #fff;
    display: inline-block;
    position: absolute;
    left: 0px;
    z-index: 9;
    padding-right: 10px;
    margin-bottom: 0px;
    bottom: 24px;
}
.section-header:before{
    content: "";
    height: 1px;
    width: 100%;
    background:#dfeaf3;
    position: absolute;
    left: 0;
    bottom: 37px;
    z-index: 9;
    opacity: .5;
}
.learning-card-left-img {
    position: relative;
    height: 130px;
    max-width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.learning-card-left-img img {
    border-radius: 7px 7px 0 0;
    margin-bottom: 7px;    
}

.learning-card-right-containt {
    padding: 10px 10px 10px 10px;
    position: relative;
}

.custom-learning-card {
    display: block;
    margin-bottom: 15px;
    background: rgba(220, 234, 249, 0.2);
    border: 1px solid #ccddec54;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 10px;
    margin-bottom: 46px;
    position: relative;
}

.learning-name {
    font-size: 12px;
    display: block;
    text-decoration: none;
    font-weight: 500;
    height: 45px;
    overflow: hidden;
    margin-bottom: 30px;
    color: #1D458A !important;
    text-align: center;
    line-height: 19px;
}

.course-name {
    font-size: 11px;
    color: #464646 !important;
}

.unit-time-card {
    margin: auto;
    font-size: 12px;
    background: #dd574e;
    box-shadow: 0 0 20px rgba(29,69,138,.3);
    border-radius: 5px;
    width: 75%;
    text-align: center;
    text-transform: capitalize;    
    margin-top: 10px;
}
.unit-time-card a{
    color: #fff !important;
    display: block;
    padding: 8px;
}
.extension_free{
    background: #1d458a!important;
    color: #fff !important;
    font-weight: 500;
}
.extension_free a{
    color: #fff !important;
    font-weight: 500;
}
.play-overley {
    background: rgb(0 0 0 / 49%);
    position: absolute;
    height: 100%;
    width: 100%;
    display: table;
}

.play-overley span {
    font-size: 52px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.play-overley span a {
    color: #e4e2e2 !important;
}

.show-more-btn {
    border: none;
    font-size: 14px;
    background: transparent;
    float: right;
    text-transform: uppercase;
    color: #12274b;
    font-weight: 500;
    position: absolute;
    right: 15px;
    z-index: 999;
}
.show-more-btn .fa{
    color: #ee9949;
    font-size: 19px;
    position: relative;
    top: 1px;
    right: -3px;
}
.completed-card {
    margin: 5px 0px 3px;
    font-size: 12px;
    font-weight: 600;
    color: #19a200;
}

.custom-progress .progress {
    height: 4px;
    border-radius: 0px !important;
}

.custom-progress {
    margin-bottom: 15px;
}
.right-containt-section{
    background: #fff;
    /* border: 1px dashed #9DB0B9; */
    padding: 18px;
    
    border-radius: 3px;
    box-shadow: 0px 0px 30px rgba(223, 228, 249, 0.5);
    position: relative;
}
.left-panel-my-account{
    background: #FAF9FD;
    /* border: 1px dashed #9DB0B9; */
    margin-bottom: 40px;
    border-radius: 3px;
    box-shadow: 0px 0px 30px rgba(223, 228, 249, 0.5);
    position: relative;
}
.padding-on-top-my-account{
    padding-top: 90px;
    padding-left: 0px;
    padding-right: 0px;
    
}

.learning-card-right-containt .ellipse{
    position: absolute;
    width: 100%;
    top: 62px;
    left: 0px;
    right: 0px;
}
.webinar-date{
    color: #2E3552;
    font-size: 12px;
    text-align: left;
    display: block;
    font-weight: 600;
}
.webinar-date .fa {
    color: #ee9949!important;
    margin-right: 4px;
}
.webinar-time{
    color: #2e3552;
    font-size: 12px;
    text-align: right;
    display: block;
    font-weight: 600;

}
.webinar-time .fa {
    color: #ee9949!important;
    margin-right: 4px;
}
.support-index-page-form .form-control{
    border: 1px solid #CBD0E6 !important;
    box-sizing: border-box;
    border-radius: 6px;
    background: #fff;
    padding: 6px 12px;

}
.support-index-page-form select, .support-index-page-form input{
    height: 40px;
    box-shadow: none !important;
}
.support-index-page-form textarea {
    box-shadow: none !important;
}
.report-submit-btn{
    margin: auto;
    font-size: 14px;
        background: #dd574e;
    box-shadow: 0 0 20px rgba(29,69,138,.3);
    border-radius: 5px;
    padding: 6px 23px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    color: #fff;
}
.report-submit-btn:disabled,
.report-submit-btn[disabled]{
    margin: auto;
    font-size: 14px;
    background: #f0918a;
    box-shadow: 0 0 20px rgba(29,69,138,.3);
    border-radius: 5px;
    padding: 6px 23px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    color: #fff;
    cursor: no-drop;

}
.react-hr-dashed-style{
    border: 1px dashed #bfd6e0 !important;
    margin-top: 43px;
    margin-bottom: 40px;

}
.tickets-hitory-table{
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 0 7px rgb(223 228 249/54%);
    display: table;
}
.tickets-hitory-table th {
    background: rgba(220,234,249,.2);
    font-weight: 500;
    color: #12274b;
}
.tickets-hitory-table td, th {
    border: 1px solid #d5e7f7;
    font-size: 13px;    
    color: #819ba6;
    padding: 10px;
}
.tickets-hitory-table td .tickets-name {
    color: #1D458A;
    font-weight: 600;
}
.view-tickets-details{
    border: none;
    background: transparent !important;
    color: #ee9949 !important;
}
.progress-circle {
    margin: 3px;
    position: relative;
    padding: 0;
    width: 16px;
    height: 16px;
    background-color: #f2e9e1;
    border-radius: 50%;
    line-height: 16px;
 }

 .progress-circle span {
    width: 36px;
    z-index: 2;
    color: #2e3552;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    text-align: center;
    display: block;
    right: -36px;
}

 .progress-circle:after{
    border: none;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #f4f6fc;
    content: " ";
    position: absolute;
 }


 .left-half-clipper {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    clip: rect(0,16px,16px,8px);
 }

 .progress-circle.over50 .left-half-clipper {
    clip: rect(auto,auto,auto,auto);
 }
 .value-bar {
    position: absolute;
    clip: rect(0,8px,16px,0);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid #ee9949;
    box-sizing: border-box;

 }

 .progress-circle.over50 .first50-bar {

    background-color: #ee9949;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    clip: rect(0,16px,16px,8px);
 }
 .progress-circle:not(.over50) .first50-bar{ display: none; }
 .course-review{
    color: #819BA6;
    font-size: 14px;
    display: block;
    text-align: right;
    margin-top: 8px;
}
.course-review .fa{
    color: #EE9949 !important;
    margin-right: 4px;
}
.left-menu-open-btn{
    height: 45px;
    line-height: 45px;
    padding: 0 7px;
    font-size: 20px;
    margin-right: 9px;
    position: absolute;
    color: #ee9949;
    width: 39px;
    display: none;
}
.custom-modal .modal-content{
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    padding: 2px;
}
.custom-modal .modal-header{
    padding: 14.5px 10px;
    margin-bottom: 12px;
    border-radius: 8px 8px 0 0;
    background: #12274b;
    position: relative;
    text-align: center;
    display: block;
}
.custom-modal .modal-header:after {
    content: '';
    width: 100%;
    height: 4px;
    background: linear-gradient(270deg,#bcdefc 0,#ff8d21 50%,#bcdefc 100%);
    position: absolute;
    left: 0;
    bottom: -4px;
}
.custom-modal .modal-header .close{
    position: absolute;
    right: 22px;
    top: 14px;
    width: 35px;
    height: 35px;
    opacity: 1;
    z-index: 999;
}
.custom-modal .modal-header .close span{
    color: #fff;
    font-weight: 100;
}
.custom-modal .modal-header .modal-title{
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
    margin-bottom: 0;
}
.custom-modal .modal-body{    
    box-sizing: border-box;
    border-radius: 7px;
    padding: 15px;
}
.custom-modal .modal-body .modal-topic{
    text-align: center;
    text-transform: uppercase;
    color: #ee9949;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 35px;
    position: relative;
}
.custom-modal .modal-body .modal-topic::after{
    content: "";
    width: 50px;
    height: 4px;
    background: #ee9949;
    position: absolute;
    margin: auto;
    left: 0px;
    right: 0px;
    bottom: -14px;
    border-radius: 50px;
}
.custom-modal .modal-body .modal-topic span{
    color: #12274B;
}
.custom-modal .modal-body .modal-topic-ponits{
    overflow: auto;
    color: #819BA6;
}
.custom-modal .custom_select_dropdown{
    width: 100%;
    height: 46px;
    padding: 0px 10px;
    border-radius: 5px;
    border-color: #BCDEFC;
}
.custom-modal textarea{
    border-color: #BCDEFC !important;
}
.react-stars:focus{
    outline: none !important;
}
.custom-modal .modal-body .form-control{
    border: 1px solid #f3f1f1;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 6px 12px;
    background: #fff;
}
.elm-react-primary-btn{
    background-color: #1D458A;
    color: #fff !important;
}
.cancel-btn{
    color: #819BA6 !important;
    background: transparent;
}
.certificate-top-tab {
	border-bottom: 1px solid #adc9e0 !important;
	padding-bottom: 1px;
	padding-left: 0px;
	padding-right: 15px;
	margin-bottom: 35px;
}
.certificate-top-tab .nav-link {
	border: none !important;
	background: #fff;
	box-shadow: 0 0 30px rgba(223, 228, 249, 0.5);
	border-radius: 7px 7px 0 0;
	color: #12274b !important;
	margin-right: 5px;
	font-weight: 500;
	font-size: 12px;
	padding: 12px 12px;
}
.certificate-top-tab .nav-link.active {
	color: #fff !important;
	background: #ee9949;
	box-shadow: 0px 0px 30px rgba(223, 228, 249, 0.5);
	border-radius: 7px 7px 0px 0px;
}
.elm-certificate-download-btn {
    color: #819ba6!important;
    font-weight: 500;
    font-size: 13px;
    border: none;
    background: transparent;
}
.elm-certificate-download-btn .fa {
	color: #ee9949 !important;
}
.certificate-for-course{
    color: #1d458a;
    text-align: center!important;
    font-weight: 500;
    margin-top: 10px;
    height: 45px;
    overflow: hidden;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 6px;
}
.certificate-progress-bar{
    height: 3px;
    border-radius: 50px;
    width: 90%;
}
.certificate-progress-bar .progress-bar{
    background-color: #EE9949 !important;
}
.certificate-progressbar{
    display: block;
    width: 100%;
    position: relative;
}
.certificate-progress{
    position: absolute;
    right: 0px;
    font-size: 11px;
    top: -7px;
    color: #1D458A;
    font-weight: 600;
}
.progress-bar-text{

    font-weight: 500;
    font-size: 12px;
    text-align: center!important;
    color: #819ba6;
    margin-bottom: 2px;

}
.courese-for-uploade{
    color: #1d458a;
    text-align: left;
    font-weight: 500;
    margin-top: 10px;
    height: 61px;
    overflow: hidden;
    font-size: 14px;
    border-bottom: 1px dashed #9db0b9;
    line-height: 22px;

}
.uploade-docoment-text{
    font-size: 18px;
    font-weight: 600;
    margin: 4px 0px 9px;
    text-transform: uppercase;
    color: #4a4a4a;
}
.uploade-point{
    font-weight: 500;
    font-size: 13px;
    color: #819BA6;
}
.uploade-btn{
    text-align: center;
}
.uploade-btn a{
    color: #EE9949;
}
.elearn-market-btn{
        background: #dd574e;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    color: #fff !important;
}
.same-height{
    height: 425px;
    overflow: hidden;
}
.uploade-docoment-box{
    position: relative;
    max-height: 278px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.uploade-docoment-box::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
background-color: #F5F5F5;
}

.uploade-docoment-box::-webkit-scrollbar
{
width: 6px;
background-color: #F5F5F5;
}

.uploade-docoment-box::-webkit-scrollbar-thumb
{
background-color: #ee9949;
border: none;
}
.bottom-btn{
    position: absolute;
    bottom: 16px;
    width: 100%;
    text-align: center;
}
.top-header-my-account{
    font-size: 22px;
    text-align: right;
    text-transform: uppercase;
    color: #ee9949;
    font-weight: 700;
    position: absolute;
    top: -41px;
    width: 100%;
    right: 13px;
}
.top-header-my-account span{
    color: #12274B;
}
.empty-section-top-box{
    width: 100%;
    height: 300px;
    display: table;
}
.empty-section-top-box .empty-section-inner-box{
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
}
.empty-section-top-box .empty-section-inner-box .empty-page-img{
    width: 85px;
}
.empty-page-text{
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    text-transform: capitalize;
    color: #12274b;
    width: 50%;
    margin: 15px auto;
}
.elm-primary-btn{
        background: #dd574e;
    box-shadow: 0 0 30px rgba(29,69,138,.2);
    border-radius: 7px;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    display: inline-block;
    text-transform: uppercase;
    color: #fff !important;
    text-align: center;
    padding: 5px 35px;
}
.reviw-box-support{
    background: rgba(255, 225, 129, 0.2);
    border-radius: 0px 20px 20px 20px;
    padding: 20px;
    position: relative;
    box-shadow: 0px 0px 2px #dedede;
}
.reviw-box-support .review-name-suport-details{
    color: #1D458A !important;
    font-weight: 500;
}
.reviw-box-support p{
    color: #819BA6;
    font-size: 13px;
    line-height: 25px;
}   
.review-text{
    background: #fff;
    padding: 20px;
}
.reviw-box-support.user-review-support{
    background: rgba(213, 231, 247, 0.25) !important;
    border-radius: 20px 0px 20px 20px;
}
.attachment-in-support-details{
    text-align: right;
    margin-top: 9px;
}
.attachment-in-support-details a{
    text-align: right;
    margin-top: 9px;   
}
.attachment-in-support-details a .fa{
    color: #12274B !important;
}
.support-status{
    text-transform: uppercase;
    color: #1D458A;
    font-size: 13px;
    font-weight: 700;
}
.support-status span{
    color: #819BA6;
}
.support-back-btn{
    padding: 0px;
    background: transparent;
    border: none;
    text-transform: uppercase;
    color: #12274b !important;
}
.support-back-btn .fa{
    color: #ee9949;
    font-size: 18px;
    position: relative;
    top: 1px;
    margin-right: 3px;
}
.profile-topic{
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 5px;
    color: #42555B;
}
.profile-topic-details{
    font-size: 14px;
    color: #819BA6;
}
.elm-btn{   
    box-shadow: 0px 0px 20px rgba(29, 69, 138, 0.3);
    border-radius: 5px;    
    text-transform: uppercase;
    font-size: 14px;
    padding: 10px 35px;
    border: none;
}

.elm-btn-primary{
    background: #CF2D37 !important;
    color: #fff !important;
}
.elm-block-btn{
    display: block;
}
.elm-small-btn{
    font-size: 12px;
    padding: 6px;
}
.delete-account-btn-elm{
    font-size: 14px;
    text-decoration: underline;
    color: #819BA6;
    margin-top: 15p
}
.settings-switch-btn .btn-primary{
    background-color: #ee9949 !important;
    border-color: #ee9949 !important;               
}
.side-menu-account  li a svg{
    width: 18px;
    height: 18px;
    margin-right: 16px;
}
/* .side-menu-account  li a svg path{
    fill: #ee9949!important;
}
.side-menu-account  li a.active img svg path{
    fill: #ee9949!important;
} */

.view-in-older-version a{
    color: #819ba6 !important;
    font-weight: 600;
    text-decoration: underline !important;
    margin-right: 6px; 
    
}
.view-in-older-version{
    position: absolute;
    top: -30px;
    left: 22px;
    z-index: 9;
}
.custom-dropedown-for-user .dropdown-item:active{
	background-color: #f8f9fa;
}
.custom-progress-bar-course{
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 10px;
    margin-top: 3px;
}
.custom-progress-bar-course .progress{
    height: 6px;
    width: 79%;
}
.custom-progress-bar-course .percentage-of-complete{
    position: absolute;
    right: 10px;
    font-size: 9px;
    font-weight: 600;
    top: -4px;
}
.play-btn-for-all-courses .fa{
    font-size: 34px;
    position: relative;
    top: 5px;
    left: 12px;
    color: #ee9949;
}

.dayes-left-expired{
    position: absolute;
    font-size: 9px;
    color: #848181;
    left: 0px;
    bottom: -8px;
}
.custom-modal-filter .modal-content{
    margin: 0px !important;
    max-width: 1000px !important;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    border-radius: 0px;
}
.custom-modal-filter .modal-body{
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    overflow: auto;
    padding: 0px;
}
.custom-modal-filter .modal-body .filter-modal-header{
    padding: 12px;
    overflow: auto;
    border-bottom: 1px dashed #ADC9E0;
}
.custom-modal-filter .modal-body .filter-modal-header span{
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    color: #12274B;
}
.custom-modal-filter .modal-body .filter-modal-header button{
    text-transform: capitalize;
    color: #EF5261;
    border: none;
    background: transparent;
    font-weight: 500;
    font-size: 12px;
}
.filter-modal-body{
    display: flex;
    flex-wrap: wrap;   
    text-align: center;
    height: 90%;
}
.filter-modal-body .left-section{
    background-color: #f1f1f1;    
    flex: 35%;

}
.filter-modal-body .left-section ul{
    list-style: none;
    font-size: 12px;
    padding: 0px;
    margin: 0px;
    text-align: left;
}
.filter-modal-body .left-section ul li a{
    display: block;
    height: 44px;
    line-height: 44px;
    padding: 0px 12px;
    text-transform: capitalize;
    color: #1D458A;
    font-weight: 500;
    border-bottom: 1px solid #e4e6e6;
    position: relative;
}
.filter-modal-body .left-section ul li a.active{
    background: #fff;
}
.filter-modal-body .right-section{
    background-color: #fff;   
    flex: 65%;

}
.filter-modal-body .right-section ul{
    list-style: none;
    font-size: 12px;
    padding: 0px;
    margin: 0px;
    text-align: left;
}
.filter-modal-body .right-section ul li a{
    display: block;
    height: 44px;
    line-height: 44px;
    padding: 0 29px 0px 12px;
    text-transform: capitalize;
    color: #819BA6;
    position: relative;
    
}
.filter-modal-body .right-section ul li a.active::after{
    content: "\f00c";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    right: 8px;
    top: 17px;
    color: #6db78b;
    font-size: 11px;
}
.custom-modal-filter .modal-footer {
    display: block;
    text-align: center;
    position: absolute;
    width: 100%;
    background: #fff;
    bottom: 0px;
    padding: 0px;
    box-shadow: 0px 0px 20px #e0e0e0;
    border: none;
}
.custom-modal-filter .modal-footer .filter-footer-left{      
    width: 47%;
    display: inline-block;
    float: left;
    color: #819BA6;

}
.custom-modal-filter .modal-footer .filter-footer-right{
    width: 47%;
    display: inline-block;
    float: left;
    color: #EE9949;

}
.custom-modal-filter .modal-footer button{
    border: none;
    background: transparent;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    width: 80%;
    padding: 8px 0px;
}
.open-filter-in-mobile-view{
    display: none;
}
.when-no-webinar-plan{
    box-shadow: 0px 0px 30px rgb(223 228 249 / 50%);
    border-radius: 7px;
    background: #fff;
    padding: 15px;
    overflow: auto;
    border: 1px solid rgba(204,221,236,.33);
}
.when-no-webinar-plan span{
    font-size: 14px;
    line-height: normal;   
    color: #819BA6;

}
.top-header-text-left{
    left: 19px !important;
    right: 0px;
    text-align: left;
}
.host-course-webinar-btn{
    font-size: 14px;
    text-align: right;
    color: #12274b;
    position: absolute;
    top: -36px;
    width: auto;
    right: 22px;
    font-weight: 600;
}
.host-course-webinar-btn a{
    color: #12274b!important;
    margin-left: 20px;
}
.host-course-webinar-btn a .fa{
    color: #ee9949;
    font-size: 13px;
}
.check-preview{
    position: absolute;
    top: -36px;
    width: auto;
    right: 173px;
}
.check-preview a{
    background: #fff;
    border-radius: 3px;
    padding: 7px 18px;
    text-transform: uppercase;
    color: #000;
    border-color: #1d458a;
    font-size: 13px;
}
.send-approval{
    position: absolute;
    top: -36px;
    width: auto;
    right: 13px;
}

.send-approval a{
    background: #1d458a;
    border-radius: 3px;
    padding: 7px 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 13px;
}
.host-course-status-tab{
    font-size: 14px;
    text-align: left;
    color: #12274b;
    position: absolute;
    top: -50px;
    width: auto;
    left: 16px!important;
    right: 0;
    font-weight: 500;
    margin-bottom: 0px;
}
.host-course-status-tab a{
    color: #12274b;
    display: block;
    float: left;
    background: #fff;
    padding: 15px 18px;
    box-shadow: 0 0 30px rgb(223 228 249 / 50%);
    border: 1px dashed transparent;
    font-size: 13px;
}
.host-course-status-tab a:hover{
    border: 1px dashed transparent !important;
}
.host-course-status-tab a.active{
    border: 1px dashed #ADC9E0;
    color: #ee9949 !important;
}
.host-course-status-tab a.active:hover{
    border: 1px dashed #ADC9E0 !important;
}
.host-course-demo-video{
    color: #42555b;
    text-align: right;
    margin-bottom: -9px;
    font-size: 13px;
    font-weight: 500;

}
.host-course-demo-video a{
    border-radius: 4px;
    color: #fff;
    background: #ee9949;
    padding: 5px 14px;
    margin-left: 6px;
}
.delete-promo-video-btn{
    background: #dd574e !important;
    color: #fff !important;
    font-size: 16px;
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 27px;
    text-align: center;
    border-radius: 50px;
    margin-left: 10px;
}
/* ===================================toast========================== */
.custom-toast-for-lms{
    position: fixed;
    bottom: 21px;
    left: 26px;
    z-index: 111;    
}
.custom-toast-for-lms .toast{
    background-color: rgb(64 63 63);
    color: #fff;
    border-radius: 4px;
    font-size: 13px;
    border: none;
}
/* ===================================end of toast========================== */
.select-address-value{
    position: relative;
    background: #f8f9fa;
    padding: 6px 30px 6px 16px;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;    
}
.select-address-value::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    position: absolute;
    right: 9px;
    top: 13px;
}
.list-of-city-state{
    position: absolute;
    width: 66%;
    z-index: 999;
    background: #fff;
    
}
.close-search-profile{
    background: transparent;
    border: none;
    padding: 6px 12px;
}
.list-of-city-state .list-for-city-state{
    overflow-Y: scroll;
    overflow-x: hidden;
    max-height: 283px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 11%);
}

.multiLanguage-dropdown{
    display: inline-block;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #ced4da;
    padding: 4px 9px;
    font-size: 12px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}
.multiLanguage-dropdown input{
    display: none;
}
.multiLanguage-dropdown .form-check{
    padding: 0px;
    cursor: pointer;
}
.multiLanguage-dropdown.active{
    padding-left: 20px;
    background: rgb(99 255 176 / 9%);
}
.multiLanguage-dropdown .form-check-label{
    cursor: pointer; 
}
.multiLanguage-dropdown.active::after{
    content: "\f00c";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    left: 4px;
    top: 8px;
    color: #108f2b;
}
.custom-form-control{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-radius: 10px;
    outline: none;
    font-size: 12px!important;
}
.go-to-my-account-dashboard a{
    background: #dd574e!important;
    color: #fff!important;
    box-shadow: 0 0 20px rgb(29 69 138 / 30%);
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 6px 13px;
    margin-right: 0px;
}
.go-to-my-account-dashboard a .fa{
    margin-left: 10px;
}
.multiLanguage-dropdown{
    display: inline-block;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #ced4da;
    padding: 4px 9px;
    font-size: 12px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}
.multiLanguage-dropdown input{
    display: none;
}
.multiLanguage-dropdown .form-check{
    padding: 0px;
    cursor: pointer;
}
.multiLanguage-dropdown.active{
    padding-left: 20px;
    background: rgb(99 255 176 / 9%);
}
.multiLanguage-dropdown .form-check-label{
    cursor: pointer; 
}
.multiLanguage-dropdown.active::after{
    content: "\f00c";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    left: 4px;
    top: 8px;
    color: #108f2b;
}
.available-coupons {   
    display: block;
    border: 1px solid #c4d9ed!important;
    padding: 16px 12px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;    
}
.available-coupons .available_coupon_name{
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #1D458A;
    font-weight: 600;
    text-transform: capitalize;

}
.available-coupons .available_coupon_details{
    color: #404040;
    display: block;
    width: 100%;
    font-size: 11px;   
}
.available-coupons .available_coupon_details span{
    color: #CF2D37;
    font-weight: 600;
}
.available-coupons .redeem_offer_btn a{
    display: inline-block;
    background:  #CF2D37;
    font-size: 13px;
    color: #fff !important;
    padding: 8px 20px;
    border-radius: 4px;
}
.available-coupons .redeem_offer_btn a .fa{
    color: #EE9949 !important;
    margin-left: 3px;
}
.available-coupon-code {
    background: #FBFDFE;
    padding: 7px 12px;
    color: #12274B;
    font-size: 14px;
    font-weight: 600;
    display: block;
    text-align: center;
    border: 1px solid #D5E7F7;
    border-radius: 100px;
    margin-bottom: 16px;
    position: relative;
}
.available-coupon-code:hover{
    border: 1px solid #D5E7F7 !important;
    color: #12274B !important;
}
.available-coupon-code .copy_icon{
    width: 15px;
    height: 15px;
    display: inline-block;
    position: absolute;
    right: 16px;
    top: 5px;
    color: #12274b!important;
}
.available-coupons .copycode{
    color: #EE9949 !important;
    font-weight: 500;
    margin-left: 8px;
    font-size: 12px;
}
.available-coupons .view_offer_btn{
    text-align: center;
    width: 100%;
    margin: 0px;
}
.available-coupons .view_offer_btn a{
    color: #1d458a!important;
    font-weight: 600;
    font-size: 13px;
}
.available-coupons .view_offer_btn a .fa{
    color: #EE9949 !important;
    margin-left: 3px;
}
.available-coupons .expires_date{
    font-size: 11px;
    font-weight: 500;
    color: #EE9949;
    margin-bottom: 0px;
}
.available-coupons .other_offer_logo{
    width: 108px;
}   
.available-coupon-details {
    color: #1d458a;
    display: block;
    width: 100%;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    height: 57px;
    overflow: hidden;
    margin-bottom: 10px;
}

.checkbox_offer_details{
    font-size: 12px;
}
.checkbox_offer_details input{
    position: relative;
    bottom: -2px;
}
.reported-date{
    font-size: 17px;
    font-weight: 500;
    color: #2e3552;
}
.market-experience{
    border:1px solid #ced4da;
    display: inline-block;
    border-radius: 10px;
    margin-bottom: 0px;
}
.market-experience a{
    padding: 5px 15px;
    color: #000;
    display: inline-block;
    cursor: pointer;
}

.market-experience input{
    width: 60px;
    text-align: center;
    border: none;
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    background-color: transparent;
 }
 .kotak_offer_box{
    display: block;
    border: 1px solid #ADC9E0!important;
    padding: 0;
    border-radius: 8px;
    overflow: auto;
    position: relative;
    margin-bottom: 20px;    
 }   
 .kotak_offer_box .kotak_logo{
    width: 90px;
}
.kotak_offer_box .offer_validity{
    font-size: 38px;
    font-family: 'Merienda One', cursive;
    color: #CF2D37;
    text-align: left;
    margin-top: -30px;
    position: relative;
    overflow: hidden;
}
.kotak_offer_box .offer_validity::after{
    content: '';
    width: 70%;
    height: 1px;
    background: #c5c5c5;
    position: absolute;
    top: 39px;
    left: 88px;    
}
.kotak_offer_box .offer_validity::before{
    content: '';
    width: 70%;
    height: 1px;
    background: #c5c5c5;
    position: absolute;
    top: 42px;
    left: 83px;  
}
.kotak_offer_box .kotak_offer_btn{
    margin-bottom: 0px;
    text-align: right;
}
.kotak_offer_box .kotak_offer_btn a{
    color: #1D458A !important;
    font-weight: 600;
    font-size: 13px;
}
.kotak_offer_box .kotak_offer_btn a .fa{
    color: #EE9949 !important;
    margin-left: 3px;
}
.kotak_offer_box .offer_validity span.month{
    text-transform: capitalize;
    font-size: 18px;
}
.kotak_offer_box .kotak_offer_name{
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #1D458A;
    font-weight: 600;
}
.kotak_offer_box .kotak_offer_details{
    color: #404040;
    display: block;
    width: 100%;
    font-size: 12px;
    height: 37px;
    overflow: hidden;
}
.offer_details_modal .modal-content{
    width: 65%;
    margin: auto;
    box-shadow: 0 0 100px rgb(0 0 0 / 40%);
    border-radius: 20px;
    overflow: hidden;
    border: none;
}
.offer_details_modal .elm_offer_logo{
    width: 130px;
    margin: auto;
}
.offer_details_modal .offer_trems_conditions{
    color: #000;
    font-size: 13px;
}
.offer_details_modal .offer_trems_conditions a{
    color: #CF2D37;
}
.offer_details_modal .offer_name{
    font-size: 26px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 35px;
    color: #12274B;
}
.offer_details_modal  .expires_date{
    color: #cf2d37;
    margin-top: 10px;
    padding-bottom: 23px;
    margin-bottom: -10px;
    font-weight: 500;
    font-size: 14px;
}
.offer_details_modal .bottom_offer_coupon{
    padding: 20px 25px;
}
.bottom_offer_coupon .coupon_code_text{
    background: #fff;
    color: #000;
    font-size: 20px;
    text-transform: uppercase;
    padding: 10px 26px;
    border: 1px dashed #ADC9E0;
    border-radius: 3px;
    margin-right: 10px;
    
}
.available-coupons .other_offer_box_details .offer_description{
    color: #42555B;
    font-weight: 400;
    font-size: 12px;
}
.available-coupons .other_offer_box_details{
    border-left: 1px solid #ADC9E0;
    padding: 10px 0px 10px 20px;
}
.available-coupons .other_offer_box_details .available_coupon_name{
    font-size: 17px;
    margin-top: 0px;
}
.available-coupons .tandc_btn{
    font-weight: 500;
    font-size: 13px; 
    color: #396CE8 !important;
}
.offer_details_modal .coupon_code_text{
    background: #fff;
    border: 1px dashed #819ba6;
    box-shadow: inset 0 5px 20px #fff;
    border-radius: 5px;
    position: relative;
    width: 85%;
    margin: auto;
    padding: 12px 10px;
    margin-bottom: 20px;
}
.offer_details_modal .coupon_code_text span:first-child{
    color: #CF2D37;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}
.offer_details_modal .coupon_code_text .copy_code{
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    right: 9px;
    top: 18px;
    color: #3c79e6;
}
.offer_details_modal .readem_oofer_top_box{   
    padding: 0px 20px 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 10px 20px rgb(173 201 224 / 25%);
} 
.empty-page-sub_text{
    font-size: 14px;
    margin-bottom: 30px;
}
.live_class_wrapper_box{
    height: 80vh;
}
.live_class_wrapper_box .live_class_inner_wrapper_box{
    padding: 40px;
    border: 2px dashed #c4d9ed;
    display: inline-block;
    margin: auto;
    border-radius: 5px;
    margin-top: 70px;
    background: #fff;
}
.live_class_wrapper_box .live_class_inner_wrapper_box .select_your_batch{
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    color: #12274B;
    line-height: 36px;
}
.custom_marquee{
    position: relative;    
    left: 0px;
    right: 0px;
    color: #f00;
    font-weight: 600;
    opacity: .7;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.custom_marquee span{    
    animation-name: rightToLeft;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    display: block;
    position: absolute;
}
.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }
  
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }
  
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }
  
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .popup_tick_bg{
    position: relative;
  }
  .popup_tick_bg .submit_popup_tick {
    width: 98px;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    top: 35px;
}
.submit_header{
    font-weight: 500;
    font-size: 26px;
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;
    color: #000;
}
.submit_header span {
    font-weight: 700;
    color: #EE9949;
}
@keyframes rightToLeft {
    0% {
      transform: translateX(100%);
      top: 11px;
      width: 100%;
    }
    20% {
      transform: translateX(-100%);
      top: 11px;
      width: 100%;
    }
    60% {
        transform: translateX(-100%);
        bottom:20px;  
        top: auto;  
        opacity: 0;   
        width: 100%; 
    }
    80% {
        transform: translateX(100%);
        bottom:20px;
        top: auto; 
        opacity: 1;
        width: 100%;
    }

    100% {
        transform: translateX(100%);
        top: 11px;
        width: 100%;
    }
  }
  .webinar_plan_offer {
    background: #fff6f0;
    border-radius: 20px;
    border: 1px solid #ffc996;
    padding: 9px 22px;
    margin-top: 34px;
    box-shadow: 0 10px 30px #dfe4f9;
}
.webinar_plan_offer .webinar_plan_offer_text {
    color: #12274b;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 21px;
}
.webinar_plan_offer_img {
    background: #ffe8d9;
    display: block;
    border-radius: 100px;
    width: 79%;
    padding: 9px;
    margin: auto;
}
.webinat_plan_btn {
    font-weight: 600;
    text-transform: capitalize;
    color: #1d458a!important;
    border: none;
    position: relative;
    font-size: 16px;
    text-decoration: underline!important;
    display: block;
    text-align: center;
}
.webinat_plan_btn svg{
    margin-left: 4px;
}
@media(max-width: 766px) and (min-width: 200px) {
    .display-in-mobile-only{
        display: block;
    }
    .help-settings .course-webinar-link-top a{
        display: none;
    }
    .user-profile-img {
        bottom: -4px;
        float: none;
        top: 11px;
        margin: 0px 0px;
        width: 70px;
        height: 70px;
        left: 13px;
    }
    .user-name {
        color: #000;
        margin-bottom: 36px;
        margin-top: 0px;
        font-weight: 500;
        padding: 0px 10px 0px 99px;
        text-align: left;
        font-size: 14px;
        height: 95px;
        line-height: 95px;
        text-transform: capitalize;
        
    }
    .author-and-student-btn {
        position: absolute;
        width: 100%;
        left: 0px;
    }
    .author-and-student-btn a{
        text-align: center !important;
        line-height: 20px !important;
    }
    .top-header {       
        overflow: hidden;
        box-shadow: 0 0 11px rgb(0 0 0 / 17%);
        background: #fff;        
        top: 0;
        width: 100%;
        z-index: 999;
        padding: 6px 7px;
        height: auto;
        line-height: inherit;
        position: fixed;
    }

    .elm-logo {
        width: 47px;
        position: relative;
        left: 49px;
        top: 0px;
    }
    .left-menu-open-btn{
        display: block;
    }
    .left-panel-my-account{
        height: 100%;
        box-shadow: 0 0 30px rgb(131 132 138 / 66%);
        border: none;
    }
    .left-menu-mobile{
        display: block;
        position: fixed;
        top: 0;
        width: 28rem;
        max-width: 82%;
        height: 100%;
        background: #fff;
        z-index: 1030;
        transform: translateX(-34.4rem);
        transition: transform 150ms cubic-bezier(.2,0,1,.9);
        left: 0;
    }
    .open-left-menu{
        transform: translateX(0);
        transition: transform 250ms cubic-bezier(0,0,.38,.9);
    }
    .left-menu-overley{
        background: rgba(30,30,28,.8);
        border-radius: 0;
        left: 0;
        opacity: 0;
        transform: scale(0);
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        transition: opacity 100ms linear,transform 0ms linear 100ms;
        z-index: 1030;
        margin: 0;
    }
    .show-overley{
        opacity: 1;
        transform: scale(1);
        transition: opacity 100ms linear;
    }
    .view-in-older-version {
        top: -79px;
        left: 0px;
        width: 100%;
        right: 0px;
        text-align: center;
    }
    .elm-logo .lms-elm-icon{
        display: none;
    }
    .elm-logo .logo-for-mobile{
        display: block;
    }
    .top-header .row .col-8{
        box-shadow: none !important;
    }
    .padding-on-top-my-account {
        padding-top: 162px;
    }
    .open-filter-in-mobile-view{
        display: block;
        position: fixed;
        background: #fff;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 99;
        height: 41px;
        line-height: 41px;
        text-align: center;
        box-shadow: 0px 0px 20px #e0e0e0;
        color: #12274b;
        text-transform: uppercase;

    }
    .open-filter-in-mobile-view .fa{
        color: #fd9601;
        margin-right: 4px;
    }
    .certificate-top-tab {        
        padding-right: 0px;
        margin-bottom: 35px;
        display: block;
    }
    .certificate-top-tab .nav-link{
        margin-right: 0px;
    }
    .certificate-top-tab .nav-link, .certificate-top-tab .nav-link.active{
        border-radius: 0px;
    }
    .top-header-my-account{
        right: 0px;
        text-align: center;
    }
    .tickets-hitory-table{
        display: block;
    }
    .when-no-webinar-plan span {
        font-size: 12px;
    }
    .go-to-my-account-dashboard a span{
        display: none;
    }
    .go-to-my-account-dashboard a {
        font-size: 18px;
        padding: 0;
        margin-right: 5px;
        height: 35px;
        width: 35px;
        line-height: 35px;
        display: block;
        text-align: center;
        border-radius: 50px;
        position: relative;
        top: 6px;
    }
    .go-to-my-account-dashboard a .fa{
        margin: 0px;
    }
    .select-address-value{
        display: block;
    }
    .available-coupon-details{
        height: auto;
        min-height: 55px;
        overflow: visible;
    }
    .available-coupons{
        height: auto;
    }
    .available-coupons .view_offer_btn{
        position: relative;
    }
    .kotak_offer_box .kotak_offer_details{
        height: auto;
    }
    .offer_details_modal .modal-content{
        width: 100%;
    }
    .offer_details_modal .elm-primary-btn{
        margin-top: 0px;
    }
    .available-coupons .other_offer_box_details{
        padding: 0px !important;
        border: none !important;
    }
    .available-coupons .other_offer_logo {       
        margin-top: 20px;
        margin-bottom: 22px;
    }
    .available-coupons .other_offer_box_details .available_coupon_name{
        font-size: 16px;      
        margin-bottom: 10px;
    }
    .available-coupons .expires_date{
        font-size: 11px;        
        text-align: left;
        margin-top: 16px;
    }
    .offer_details_modal .coupon_code_text{
        width: 100%;
    }
    .empty-section-inner-box .elm-btn{
        display: block;
        margin-right: 0px !important;
        margin-bottom: 20px;
    }
    .empty-page-text{
        width: 100%;
    }
    .live_class_wrapper_box .live_class_inner_wrapper_box{
        padding: 20px;
        margin: 70px 20px;
    }
    .live_class_wrapper_box .live_class_inner_wrapper_box .select_your_batch {        
        font-size: 15px;      
        line-height: 27px;
    }
    .webinar_plan_offer_img{
        width: 100px;
        height: 100px;
    }
}
.mytooltip{
    font-size: 10px!important;
    padding: 3px 5px!important;
}
.vjs-poster{
    background-size: auto;
}
.footer-headding {
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    text-transform: uppercase;
    color: #12274B;
    position: relative;
    margin-bottom: 27px;
}
.footer-headding::after {
    content: '';
    background: #ee9949;
    height: 3px;
    width: 42px;
    position: absolute;
    left: 0px;
    bottom: -8px;
    border-radius: 50px;
}
.footer-menu {
    margin: 0px;
    padding: 0px;
}
.footer-menu li {
    list-style: none;
    margin-bottom: 4px;
}
.footer-menu li a {
    font-size: 12px;
    color: #819BA6;
}
.footer-text {
    font-size: 12px;
    color: #819BA6;
    margin-bottom: 8px;
}
.text-left-pc {
    text-align: left;
}
.text-right-pc {
    text-align: right;
}
.footer-menu li span {
    font-size: 12px;
    color: #819BA6;
}
.footer-image-container img{
    margin-right: 5px;
}
.topic-heading-style-index{
    text-transform: uppercase;
    color: #EE9949;
    font-weight: 700;
    font-size: 30px;
    position: relative;
    margin-top: 20px;
}
.topic-heading-style-index span{
    color: #12274B;
}
.topic-sub-heading-style-index{
    font-size: 19px;
    margin-top: 20px;
    margin-bottom: 30px;
    position: relative;
}
.topic-sub-heading-style-index::after {
    content: '';
    background: #ee9949;
    height: 4px;
    width: 71px;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;    
    bottom: -8px;
    border-radius: 50px;
}
.custom-card{
    border: 1px solid #dee4e8;
    box-shadow: 0px 10px 30px rgb(223 228 249 / 80%);
    margin-bottom: 40px;
}