.host-course-card .course-type{
    color: #1D458A;
    font-weight: 500;
    font-size: 10px;
}
.host-course-card .course-type .fa{
    color: #fe9700 !important;
}
.host-course-card .learning-card-right-containt{
    padding: 0px;
}
.host-course-card .learning-card-right-containt .learning-name{
    text-align: left;
    color: #12274B;
    margin-bottom: 5px;
    color: #12274b !important;
}
.host-course-price-section{
    padding-top: 8px;
    padding-bottom: 8px;
    background: #fff;
    border: 1px solid rgba(204,221,236,.33);
    margin-left: -11px;
    margin-right: -11px;
    margin-top: 8px;
}
.host-course-price-section .course-price-section{
    font-size: 11px;
    color: #819BA6;
    font-weight: 400;
}
.host-course-price-section .course-price-section .fa{
    font-size: 10px;
}
.host-course-card .host-course-bottom-btn-section{
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
}
.host-course-card .host-course-bottom-btn-section li{
    flex: auto;
    text-align: center;
    border-right: 1px solid rgba(204,221,236,.33);
}
.host-course-card .host-course-bottom-btn-section li a{
    color: #1d458a!important;
    font-weight: 500;
    font-size: 11px;
    display: block;
    padding: 11px 0;
}
.host-course-card .host-course-bottom-btn-section li:last-child{
    border-right: none;
}
.add-course-form-label{
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
    margin-top: 20px;
    font-size: 15px
}
.add-course-form-label .fa{
    color: #ee9949;
    margin-left: 3px;
    font-size: 12px;
}
.form-box-options{
    border: 1px solid #cbd0e6;
    display: flex;
    border-radius: 8px;
}
.form-box-options .form-check{
    flex: auto;
    padding: 4px 16px;
    margin: 0px;
}
.tox-editor-container .tox-toolbar__primary{
    background: #eee !important;
    border-bottom: 1px solid #ccc;
}
.host-section-header{
    background: #2E5DAD;
    color: #fff;
    padding: 12px 0px;
    border-radius: 8px 8px 0px 0px;
    margin-top: 12px;
    box-shadow: 0px 0px 5px rgb(122 171 214 / 63%);
}
.open-host-section-header{
    background: #174490;
    width: 20px;
    display: block;
    float: right;
    text-align: center;
    height: 20px;
    border-radius: 50px;
    line-height: 20px;
}
.host-batch-section-header{
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 12px rgb(165 172 177 / 18%);
}
.inner-host-section-header{
    background: rgb(220 234 249 / 42%);
    padding: 14px;
    margin-bottom: 5px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #D5E7F7;
}
/* .inner-host-section-header:hover{
    border: 1px solid rgba(204,221,236,.33) !important;
} */
.inner-chapter-section-header{
    border: none;
    background: transparent;
    padding: 0px 4px;
    color: #1D458A;
    font-weight: 600;
    font-size: 13px;
    display: block;
    overflow: auto;
}
.inner-unit-section-header{
    color: #ee9949!important;
    margin-top: 14px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid #D5E7F7 !important;
}
.inner-unit-section-header:hover{
    border: 1px solid #D5E7F7 !important;
}
.host-chapter-header{
    border: 1px solid rgba(204,221,236,.33) !important;
    background: #fff;
    padding: 5px 0px 10px 0px;
    box-shadow: 0px 0px 7px rgb(122 171 214 / 18%);
}
.open-host-chapter-header{    
    background: #d3e4f5;
    width: 20px;
    display: block;
    float: right;
    text-align: center;
    height: 20px;
    border-radius: 50px;
    line-height: 20px;
    color: #446bb9;
}
.manage-chapter-box{
    background: rgb(249 249 249);
    padding: 7px 0 7px;
    text-align: left;
    border: 1px solid rgba(204,221,236,.33)!important;
    font-size: 12px;
    color: #12274b;
    font-weight: 500;
    box-shadow: none;
}
.manage-chapter-box{
    font-size: 12px;
    color: #12274b;
    font-weight: 500;
}
.manage-chapter-box .fa{
    color: #ee9949;
    margin-left: 9px;
}
.batch-date-in-host-batch{
    color: #ee9949;
    margin-bottom: 0;
    font-size: 13px;
    padding: 0 4px;
    margin-top: 7px;
}
.add-session-btn{
    color: #12274b;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 14px;
    margin-right: 6px;

}
.add-session-btn .fa{
    color: #ee9949;
    margin-left: 9px;
}
.dashboard-top-section p{
    font-size: 17px;
    color: #12274b;
    text-transform: uppercase;
    font-weight: 600;
}
.dashboard-top-inner-section{
    position: relative;
    overflow: hidden;
    padding: 23px 10px;
    margin-bottom: 17px;
    padding-bottom: 15px;
    margin-top: 19px;
}
.dashboard-top-inner-section::after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 50%;
    height: 220px;
    box-shadow: inset 0 0 30px rgb(208 216 249);

}
.dashboard-top-inner-section h2{
    color: #1D458A;
    font-size: 28px;
    margin-top: 12px;
    font-weight: 700;
    margin-bottom: 1px;
}
.dashboard-top-inner-section span{
    color: #ee9949;
    display: block;
    font-size: 12px;
    font-weight: 500;
}
.host-webinar-card-btn{
    color: #dd574e!important;
    font-weight: 500;
    text-align: center;
    display: block;
    padding: 6px 0px;
    background: transparent;
    border: none;
}
.host-webinar-card-btn:hover{
    background: transparent !important;
    border: none !important;
}
.tab-for-host-unit-section{
    padding: 27px 15px;
    border: 1px solid #cbd0e6!important;
    margin-bottom: 27px;
    background: #f8fbfe;
    border-radius: 0px 0px 8px 8px !important;
}
.author-section-btn{
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    padding: 10px 35px;
    cursor: pointer;
    transition: all .6s;
    border: none;
    display: inline-block;
}
.author-red-btn{
    background: #dd574e !important;
    color: #fff !important;
}
.author-orange-btn{
    background: #EE9949 !important;
    color: #fff !important;
}
.author-blue-btn{
    background: #1D458A !important;
    color: #fff !important;
}
.author-red-btn.only-text-color{
    background-color: transparent !important;
    color: #dd574e !important;
    font-weight: 600;
}
.author-cancel-btn{    
    color: #819BA6 !important;
}
.author-cancel-btn.only-text-color{
    background-color: transparent !important;
    color: #819BA6 !important;
}
.author-modal-header{
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    color: #12274B;
    font-size: 22px;
    position: relative;
}
.author-modal-header::after{
    content: '';
    background: #ee9949;
    height: 3px;
    width: 40px;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    bottom: -11px;
    border-radius: 50px;
}
.author-modal-header-none{
    padding: 0;
    text-align: right;
    display: block;
    border: none;
}
.author-modal-header-none .close{
    margin: 7px 15px;
    padding: 0px;
}
.host-section-units{
    background: #ffffff!important;
    box-shadow: none;   
    border: 1px solid #eee;
    color: #12274b;  
}
.host-section-units p{
    color: #446bb9 !important;
    font-weight: 600;
}
.create-game-dropdown span{
    border: 1px solid #cbd0e6!important;
    padding: 10px 48px 10px 31px;
    background: #fff;
    display: inline-block;
    border-radius: 6px;
    position: relative;
    margin-bottom: 0px;
    margin-top: 11px;
}
.create-game-dropdown .fa{
    position: absolute;
    right: 12px;
}
.custom-breadcrumb-author .breadcrumb{
    background: transparent;
    padding: 0px;
    font-size: 11px;
    margin-bottom: 0px;
}
.custom-breadcrumb-author .breadcrumb .breadcrumb-item a{
    color: #819BA6;
}
.custom-breadcrumb-author .breadcrumb .breadcrumb-item.active{
    color: #ee9949!important;
}
.upload-video-unit{
    position: fixed;
    right: 1%;        
    bottom: 2%;
    z-index: 9999;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #eee;
    min-width: 350px;
}
.upload-video-unit .close{
    position: absolute;
    text-shadow: none;
    opacity: 1;
    background: #eee;
    width: 18px;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #000;
    border-radius: 50%;
    top: 2px;
    right: 8px;
    z-index: 999;
}
.upload-video-unit .upload-video-unit-header{
    background: #3c3c3c;
    color: #fff;
    margin-bottom: 0px;
    padding: 10px;
    font-size: 11px;
}
.upload-video-unit .upload-video-unit-details{
    padding: 15px;
    color: #819BA6;
    background: #fff;
    font-size: 13px;
}
.author-and-student-btn{
    margin-bottom: 0px;
    display: flex;
    margin-top: 8px;
}
.author-and-student-btn a{
    font-size: 11px!important;
    flex: auto;
    background: #dfe4f9;
    padding: 6px 6px;
    margin: 4px;
    color: #12274b;
    border-radius: 4px;
    text-decoration: none;
}
.author-and-student-btn a.active{
    background:#ee9949!important;
    color: #fff;
}
.view-summary-report-btn{
    box-shadow: 0 10px 13px -5px rgb(223 228 249 / 42%);
    text-align: center;
    padding-bottom: 35px;
    margin-bottom: 49px;
    margin-top: 30px;
    margin-left: -18px;
    margin-right: -18px;
}
.view-summary-report-btn a{
    background: #1d458a;
    color: #fff!important;
    text-transform: uppercase;
    font-size: 15px;
    padding: 10px 22px;
    display: inline-block;
    border-radius: 4px;
}
.view-btn-course-deiscussion{
    color: rgb(255 255 255);
    font-weight: 500;
    font-size: 10px;
    background: #1d458a;
    padding: 4px 14px;
    border-radius: 4px;
}
.view-btn-course-deiscussion a{
    color: #fff;
}
.upload-course-img-btn-box{
    display: table;
    height: 100%;
    width: 100%;
}
.upload-course-img-btn-inner-box{
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: center;
}
.upload-image-container{
    height: 270px;
    width: 450px;
}
.upload-image-container img{
    width: 100%;
}
.host-section-header .drag-icon svg{
    width: 17px;
    height: 17px;
    margin-right: 4px;
}
.inner-host-section-header .drag-icon svg{
    width: 18px;
    height: 18px;
    margin-right: 6px;
}
.inner-unit-section-header .drag-icon svg{
    width: 15px;
    height: 15px;
    margin-right: 6px;
}
.inner-host-section-header .drag-icon svg path, .inner-unit-section-header .drag-icon svg path{
    fill: #5d8fb9 !important;
}
.section-header.for-edit-course-details-page{
    height: 38px;
}
.section-header.for-edit-course-details-page p{
    bottom: 0px;
}
.section-header.for-edit-course-details-page:before{
    bottom: 13px;
}
.add-section-btn-box{
    background: rgba(213,231,247,.2);
    border: 1px solid #d5e7f7;
    border-radius: 7px;
    text-align: center;
    padding: 40px 0px;
    margin-top: 49px;
    margin-bottom: 50px;
}
.author-report-top-header{
    margin-bottom: 12px;
    margin-top: 38px;
    color: #1d458a;
    font-weight: 600;
    font-size: 16px;
}
.upload-course-video-name-unit{
    background: #e7f2fd;
    padding: 10px;
    border-radius: 4px;
}
.dashboard-current-month-text{
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-weight: 600;
    color: #ee9949;
    position: relative;
}
.dashboard-current-month-text::after{
    content: "";
    background: #1d458a;
    height: 4px;
    width: 42px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -8px;
    border-radius: 50px;
}
.upload-course-video-name-unit .close-btn {
    border: none;
    background: transparent;
    color: #dd574e;
    font-size: 16px;
}