@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merienda+One&display=swap);
body {
	font-family: "Poppins", sans-serif;
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: left;
	background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.5rem;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.2;
	color: inherit;
}

.h1,
h1 {
	font-size: 25px;
}

.h2,
h2 {
	font-size: 20px;
}

.h3,
h3 {
	font-size: 17px;
}

.h4,
h4 {
	font-size: 15px;
}

.h5,
h5 {
	font-size: 13px;
}

.h6,
h6 {
	font-size: 12px;
}

p {
	margin-top: 0;
	margin-bottom: 14px;
}

.small,
small {
	font-size: 80%;
	font-weight: 400;
}

a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}

a:hover {
	color: #0056b3;
	text-decoration: underline;
}

button:focus {
	outline: none !important;
	box-shadow: none !important;
}

.lms-elm-icon {
	width: 128px;
	position: relative;
	top: -6px;
}
.top-header-style {
	height: 73px;
	line-height: 73px;
	overflow: hidden;
	box-shadow: 0 0 20px rgb(170 191 212 / 30%);
}

.course-name-top-header {
	color: #12274b !important;
	font-size: 16px;
	font-weight: 600;
	text-decoration: none;
	margin: 0px;
}

/* .course-name-top-header a {
	color: #12274B  !important;
	font-size: 16px;
	font-weight: 600;
	text-decoration: none;
} */

/* .course-name-top-header::before {
	content: "";
	width: 1px;
	background: #989898;
	height: 40%;
	position: absolute;
	left: 0px;
	top: 13px;
} */

.back-button-e-learn {
	color: #000 !important;
	display: none;
}

.main-container-box {
	display: flex;
}

.left-section-for-elearn {
	width: 75%;
}

.right-section-for-elearn {
	position: fixed;
	right: 0;
	width: 25%;
	top: 75px;
	height: 90%;
	box-shadow: -1px 0px 4px rgb(0 0 0 / 2%);
}

.face-to-face-video {
	width: 100%;
}

.e-learning-content-section {
	/* display: flex; */
	position: relative;
	justify-content: center;
	padding: 40px 0px;
	/* background: linear-gradient(180deg, rgba(245, 243, 253, 0) 0%, #C4D9ED 100%); */
	background-image: url(/static/media/backgroundImage.a2a59aab.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.e-learning-content-section .previous-button {
	position: absolute;
	background: transparent;
	border: none;
	color: #fff;
	top: 50%;
	line-height: 51px;
	width: 42px;
	text-align: center;
	font-size: 25px;
	z-index: 9;
	left: 10px;
}

.e-learning-content-section .next-button {
	position: absolute;
	background: transparent;
	border: none;
	color: #fff;
	top: 50%;
	line-height: 51px;
	width: 42px;
	text-align: center;
	font-size: 25px;
	z-index: 9;
	right: 10px;
}
.next-previous-icon {
	max-width: 100%;
	height: auto;
}

.e-learning-dashboard-section {
	padding: 40px 15px 18px 15px;
	background: #faf9fd;
}

.lms-controller-nav-bar {
	list-style: none;
	border-bottom: 1px dashed #c4d9ed;
	margin-left: 0px;
	padding-left: 0px;
	padding-bottom: 3px;
}

.lms-controller-nav-bar li {
	display: inline-block;
	height: 35px;
	line-height: 35px;
}

.lms-controller-nav-bar li a {
	text-decoration: none;
	padding: 0 27px;
	display: block;
	background: #fff;
	border-radius: 3px 3px 0px 0px;
	font-weight: 500;
	font-size: 14px;
	color: #12274b;
	margin-right: 10px;
}

.active-lms-controller {
	color: #fff !important;
	background: #ee9949 !important;
}

/* .active-lms-controller::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 2px;
	background: #000;
	left: 0px;
	bottom: -15px;
} */

.only-for-mobile {
	display: none !important;
}

.top-header-lms-menu {
	font-size: 18px;
	font-weight: 600;
	padding: 21px 10px;
	margin: 0;
	background: #faf9fd;
	border-bottom: 1px dashed #c4d9ed;
	margin-left: 2px;
	margin-bottom: 2px;
	padding-bottom: 9px;
	text-transform: uppercase;
}

.close-btn-for-lms-menu {
	float: right;
	color: #12274b !important;
	font-size: 18px;
	position: relative;
	top: -9px;
	cursor: pointer;
}

.display-none-side-menu-lms .left-section-for-elearn {
	width: 100%;
	overflow: hidden;
}

.display-none-side-menu-lms .right-section-for-elearn {
	display: none;
}

.show-side-menu-lms-btn {
	display: none;
	position: absolute;
    right: -148px;
    background: #505761;
    border: none;
    color: #fff;
    height: 42px;
    top: 13px;
    line-height: 24px;
    font-size: 17px;
    z-index: 9;
    padding: 0 8px;
    transition: all .6s;
    min-width: 179px;
    text-align: left;
}

.show-side-menu-lms-btn:hover {
	right: 0px;
	transition: all 0.6s;
}

.display-none-side-menu-lms .show-side-menu-lms-btn {
	display: block;
}

.course-content-list {
	list-style-type: none;
	font-size: 14px;
	font-weight: 500;
	background-color: #fff;
	cursor: pointer;
	position: relative;
	padding: 20px 10px;
	color: #12274b;
	border-bottom: 1px dashed #c4d9ed;
}

/* .course-content-list:hover {
	background-color: #eeeeef;
} */

.course-content-list-active {
	list-style-type: none;
	font-size: 14px;
	font-weight: 500;
	background-color: #fff;
	cursor: pointer;
	position: relative;
	padding: 20px 10px;
	color: #12274b;
	border-bottom: 1px dashed #c4d9ed;
}

.small-text-under-section {
	font-size: 10px;
	font-weight: 400;
	color: #5BC716;
	text-transform: none;
}
.if-not-complete{	
	color: #EE9949 !important
}
.chapter-name {
	font-weight: 500;
	font-size: 12px;
	cursor: pointer;
	color: #1d458a;
	display: block;
	padding: 15px 30px 15px 10px;
}

.chapter-block {
	background: #faf9fd;
	margin: 2px;
	border-bottom: 1px dashed #c4d9ed;
	position: relative;
}
.chapter-box {
}
.chapter-collapse {
	position: absolute;
	right: 9px;
	top: 14px;
}

.chapter-collapse .fa {
	color: #12274b;
	font-size: 13px;
}

.chapter-block a {
	color: inherit;
}

.chapter-block a:hover,
a:active {
	color: inherit;
	text-decoration: none;
}

.unit-name {
	font-size: 13px;
	background-color: transparent;
	padding: 15px 31px 15px 10px;
	color: #819ba6;
	font-weight: 400;
}
.unit-name-active {
	background: #f1a854;
	color: #101725;
	font-weight: 600;
}

.check-box-chapter {
	position: absolute;
	right: 0px;
}
.unit-name-box{	
    display: block;
    width: 100%;
	overflow: auto;
}
.unit-name-box .fa {
	display: inline-block;
    float: left;
    width: 28px;
    margin-top: 4px;
}
.unit-name-text{
	display: inline-block;
    float: left;
    width: 88%;
    position: relative;
}
.video-time{
	display: block;
    margin-left: 28px;
    font-size: 11px;
    font-weight: 300;
    color: #b7c5cc!important;
    width: 80%;
    float: left;

}
.video-time .fa{
	width: auto;
    float: none;
}
.unit-name input[type="checkbox"] {
	width: 30px;
	height: 17px;
	padding-left: 0.35em;
	float: left;
	display: none;
}
.unit-name input[type="checkbox"] + span {
	float: left;
	position: relative;
	width: 20px;
	height: 19px;
	vertical-align: middle;
	background: transparent;
	border: none;
	cursor: pointer;
	margin-left: 5px;
	margin-right: 3px;
}
.unit-name input[type="checkbox"]:checked + span {
	background: transparent;
	border: none;
}
.unit-name input[type="checkbox"]:checked + span:after {
	content: "\f00c";
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 11px;
	color: #477529;
}
.unit-name input[type="checkbox"] + span:after {
	content: "\f00c";
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 11px;
	color: #e5f0f3;
}

.unit-name input[type="checkbox"] + span {
	margin-right: 4px;
}
.side-menu-for-lms {
	height: 91%;
}

.icon-small {
	font-size: 0.8rem;
}

.section-class {
	height: 444px;
	width: 750px;
	margin: auto;
	border: 2px solid;
	border-radius: 5px;
}

.section-class-webinar {
	height: 600px;
}
.section-class-html {
	font-size: 12px;
	line-height: 24px;
	color: #3c5159;
	padding: 10px 18px;
	background-color: #FFFFFF;
}
.section-class-html .start-btn {
	background: #ee9949;
	box-shadow: 0px 0px 20px rgba(238, 153, 73, 0.2);
	border-radius: 3px;
	color: #fff;
}

.side-menu-navigation {
	position: absolute;
	right: 9px;
	top: 19px;
	color: #ee9949;
}

.chapter-name-hedding {
	position: relative;
	padding-right: 34px;
}

.chapter-name-hedding .side-menu-navigation {
	position: absolute;
	right: 6px;
	top: 6px;
	font-size: 11px;
}
.section-class-test {
	min-height: 500px;
	height: auto;
	width: 750px;
	margin: auto;
}
.course-test {
	background: #ffffff;
	border: 1px solid #d5e7f7;
	box-sizing: border-box;
	box-shadow: 0px 0px 30px rgba(223, 228, 249, 0.5);
	border-radius: 7px;
	padding: 0px 0px 20px 0px;
	overflow: hidden;
}
.course-test-container {
	font-size: 12px;
	line-height: 24px;
	color: #819ba6;
	padding: 10px 18px;
	position: relative;
}

.course-test-container p {
	font-weight: 700;
	color: #000;
}
.course-test-option {
	background: rgba(220, 230, 235, 0.1);
	border: 1px solid #d5e7f7;
	box-sizing: border-box;
	padding: 13px 13px;
	position: relative;
}
.course-test-option p {
	font-weight: 500;
	font-size: 14px;
	color: #6378ae;
	margin-bottom: 0px;
}

.course-test-option input {
	width: 17px;
	height: 17px;
	position: relative;
	top: 4px;
	border: 1px solid #d5e7f7;
	margin-right: 10px;
}

.course-test-container button {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	margin-right: 10px;
	border: none;
	padding: 5px 19px;
	text-transform: uppercase;
}
.test-btn {
	box-shadow: 0px 0px 20px rgba(238, 153, 73, 0.2);
	border-radius: 3px;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
	text-transform: capitalize;
	margin-top: 2rem;
}
.previous-btn {
	background: #ee9949;
}

.next-btn {
	    background: #dd574e;
}
.submit-btn {
	background: #12920d;
}
.course-test-name {
	background: #2e5dad;
	padding: 14px 15px;
	color: #fff;
	font-size: 16px;
}
.number-of-questions-box {
	background: #faf9fd;
	border-bottom: 1px dashed #c4d9ed;
	padding: 27px 16px 10px;
	border-left: 2px solid #fff;
}

.total-question-number-box {
	display: block;
	height: 100%;
	width: 100%;
	margin-top: 30px;
}
.number-of-questions-box h2 {
	font-weight: 600;
	font-size: 18px;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #12274b;
}
.course-test-option .for-wrong-answer {
	position: absolute;
	right: 12px;
	top: 8px;
	font-size: 16px;
	color: #ff6465;
}

.course-test-option .for-correct-answer {
	position: absolute;
	right: 12px;
	top: 8px;
	font-size: 16px;
	color: #5bc716;
}

.answer-details-heading {
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 30px !important;
	color: #ee9949 !important;
	margin-bottom: 0px !important;
	position: relative;
}

.answer-details-heading span {
	color: #12274b !important;
}

.answer-details-box {
	box-shadow: 0px 5px 30px rgba(223, 228, 249, 0.5);
	margin-top: 0px;
	padding: 12px 9px;
	border-radius: 7px;
}

.answer-details-box .answer-details-text {
	font-size: 12px !important;
	line-height: 20px !important;
	color: #819ba6 !important;
	margin-bottom: 0px !important;
	font-weight: 400 !important;
	margin-top: 5px;
}

.answer-details-heading .answer-details-collaps {
	position: absolute;
	right: 4px;
	font-size: 20px;
}

.total-score-box {
	background: #faf9fd;
	border-bottom: 1px dashed #c4d9ed;
	padding: 20px 16px 20px;
	border-left: 2px solid #fff;
}

.total-score-box p {
	background: #ffffff;
	border: 1px solid #d5e7f7;
	box-sizing: border-box;
	display: block;
	overflow: auto;
	padding: 6px 8px;
	margin-bottom: 0px;
	font-weight: 500;
	font-size: 14px;
	line-height: 30px;
	text-align: right;
	color: #1d458a;
}

.number-of-questions-ans {
	background: #fff;
	border-bottom: 1px dashed #c4d9ed;
	padding: 20px 16px 20px;
	border-left: 2px solid #fff;
}

.number-of-questions-ans h2 {
	font-weight: 600;
	font-size: 15px;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #ee9949;
}

.number-of-questions-ans h2 span {
	color: #1d458a;
}

.number-of-questions-ans .total-question-number-box {
	display: block;
	height: 100%;
	width: 100%;
	margin-top: 30px;
}

.number-of-questions-ans .total-question-number-box button {
	border: none;
	background: #cbd3d7;
	border: 1px solid #819ba6 !important;
	box-sizing: border-box;
	border-radius: 3px;
	width: 36px;
	text-align: center;
	height: 26px;
	font-size: 12px;
	color: #6378ae;
	font-weight: 500;
	margin-bottom: 6px;
}

.number-of-questions-ans .total-question-number-box button.correct-q {
	background: #d0f0c2 !important;
	border: 1px solid #5bc716 !important;
	color: #3d9c00 !important;
}

.number-of-questions-ans .total-question-number-box button.wrong-q {
	background: #f9cfd2 !important;
	border: 1px solid #ea6f70 !important;
	color: #d75050 !important;
}

.number-of-questions-ans .question-Attempted-box ul li span {
	background: #819ba6;
	border: 1px solid#819BA6;
	box-sizing: border-box;
	border-radius: 1px;
	display: block;
	width: 13px;
	float: left;
	height: 13px;
	position: relative;
	top: 2px;
	margin-right: 5px;
}

.number-of-questions-ans .question-Attempted-box ul li span.correct-q {
	background: #5bc716;
	border: 1px solid #5bc716;
}

.number-of-questions-ans .question-Attempted-box ul li span.wrong-q {
	background: #ea6f70;
	border: 1px solid #ea6f70;
}

.Leaderboard-section {
	background: #faf9fd;
	border-bottom: 1px dashed #c4d9ed;
	padding: 20px 16px 20px;
	border-left: 2px solid #fff;
}

.Leaderboard-section table {
	width: 100%;
	border-collapse: collapse;
}

.Leaderboard-section table,
td,
th {
	border: 1px solid #d5e7f7;
}

.Leaderboard-section table,
th,
td {
	font-size: 12px;
	
	color: #819ba6;
	padding: 7px;
}

.Leaderboard-section h2 {
	font-weight: 600;
	font-size: 18px;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #12274b;
	margin-bottom: 20px;
}

.total-question-number-box button {
	border: none;
	background: #ffffff;
	border: 1px solid #d5e7f7;
	box-sizing: border-box;
	border-radius: 3px;
	width: 36px;
	text-align: center;
	height: 26px;
	font-size: 12px;
	color: #6378ae;
	font-weight: 500;
	margin-bottom: 6px;
	margin-right: 5px;
}

.total-question-number-box button.Attempted-q {
	background: #fbeece;
	border: 1px solid rgba(238, 153, 73, 0.29);
}

.total-question-number-box button.current-q {
	background: #2e5dad;
	border: 1px solid #2e5dad;
	color: #fff;
}

.question-Attempted-box {
	margin-top: 20px;
}

.question-Attempted-box ul {
	padding: 0px;
	list-style: none;
	display: block;
	overflow: auto;
	width: 100%;
}

.question-Attempted-box ul li {
	float: left;
	font-size: 11px;
	text-transform: capitalize;
	color: #819ba6;
	padding: 0px 6px;
}

.question-Attempted-box ul li span {
	background: #ffffff;
	border: 1px solid #d5e7f7;
	box-sizing: border-box;
	border-radius: 1px;
	display: block;
	width: 13px;
	float: left;
	height: 13px;
	position: relative;
	top: 2px;
	margin-right: 5px;
}

.question-Attempted-box ul li span.Attempted-q {
	background: #fbeece;
	border: 1px solid rgba(238, 153, 73, 0.29);
}

.question-Attempted-box ul li span.current-q {
	background: #2e5dad;
	border: 1px solid#2E5DAD;
}

.test-submit-btn {
	background: #ee9949;
	box-shadow: 0px 0px 20px rgba(238, 153, 73, 0.2);
	border-radius: 3px;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff !important;
}
.course-test-no {
	/* width: 90%; */
	text-align: right;
	margin: 9px auto;
	font-size: 14px;
	font-weight: 600;
	color: #10a529;
}
.course-result-container {
	height: 600px;
	margin: 10px 12px;
}
.answer-wrong {
	background-color: #df7721;
}
.answer-correct {
	background-color: #21df96;
}
.answer-wrong p {
	color: #000 !important;
	font-weight: 600 !important;
}
.answer-correct p {
	color: #000 !important;
	font-weight: 600 !important;
}
.webinar-full-video .video-js .vjs-tech {
	position: relative;
}
.webinar-full-video .html5-video-player .video-click-tracking,
.html5-video-player .video-stream {
	position: relative !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
}
.custom-toster {
	color: #fff;
	background: rgb(35, 35, 35);
	text-align: center;
	padding: 0px 10px;
	font-size: 17px;
	position: fixed;
	left: 0px;
	right: 0px;
	margin: auto;
	width: 330px;
	top: 23px;
	font-weight: 500;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.go-to-my-account {
	display: block;
    width: 43px;
    float: right;
    margin-right: 14px;
}
.goto-my-account-btn {
    background: rgb(238 153 73/27%);
    padding: 13px 17px;
    color: #12274b!important;
    font-weight: 600;
    border-radius: 7px;
    font-size: 12px;
}

.percentage-of-complete-in-header{
	font-weight: 600;
    font-size: 12px;
    line-height: 30px;
    color: #1D458A;
}
.side-menu-for-lms .ScrollbarsCustom-Wrapper {
	right: 0px !important;
}
.side-menu-for-lms .ScrollbarsCustom-Track {
	width: 5px !important;
	background: rgb(255 255 255 / 10%) !important;
}
.side-menu-for-lms .ScrollbarsCustom-Thumb {
	background: rgb(240 167 100 / 37%) !important;
}

.q-a-section {
	width: 100%;
	padding: 25px 10px;	
	position: relative;
	border-bottom: 1px solid #d5e7f7;
}

.transcript_active{
  background-color: #a82828;
  color: white;
}

.user-image-q-and-a {
	width: 50px;
	border-radius: 50px;
	height: 50px;
	overflow: hidden;
	float: left;
	margin-right: 15px;
	border: 1px dashed #9db0b9;
	padding: 1px;
}

.user-image-q-and-a img {
	border-radius: 50%;
}
.user-question-q-and-a {
	overflow: hidden;
}

.q-and-a-question {
	width: 100%;
	display: block;
	float: left;
	padding-right: 24px;
}

.q-and-a-like {
	float: right;
	padding: 0px !important;
}

.q-and-a-like a {
	color: #000;
	margin-right: 6px;
	text-decoration: none;
}

.q-and-a-like a .fa {
	color: #bbb4b4;
}

.q-and-a-question .question {
	font-weight: 500;
	font-size: 16px;
	color: #ee9949;
	margin-bottom: 7px;
	height: auto;
}

.q-and-a-question .ans {
	font-style: italic;
	font-weight: normal;
	font-size: 13px;
	color: #819ba6;
	height: auto;
}

.question-date {
    color: #6378ae;    
	overflow: auto;
    
}
.question-date span {
	font-size: 12px;
    padding: 10px 10px;
    display: inline-block;
}
	


.see-more-question-btn span {
	display: block;
	text-align: center;
	border: 1px solid #c5c5c5;
	min-width: 300px;
	margin: 20px auto 10px;
	padding: 9px 9px;
	font-size: 15px;
	font-weight: bolder;
	color: #565656;
	text-decoration: none;
	border-radius: 4px;
	cursor: pointer;
}

.question-reply-page .main-question {
	border: none;
}

.question-reply-page .q-a-section:hover {
	background: transparent;
}

.question-reply-page .q-a-section .q-and-a-question .question,
.question-reply-page .q-a-section .q-and-a-question .ans {
	height: auto;
}
.lms-dashboard-container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	margin-top: 50px;
	margin-bottom: 30px;
}

.display-none-side-menu-lms .lms-dashboard-container {
	max-width: 1100px;
}
.view-reply {
	cursor: pointer;
	color: #ee9949 !important;
}
.result-box {
	padding: 14px 10px;
	border-bottom: 1px solid #eee;
}
.result-box:hover {
	background-color: #ebedef;
}
.result-box div {
	padding: 8px;
}
.result-box div p {
	margin-bottom: 0px;
	font-weight: 500;
	color: #545454;
}
.course-result-container .result-box:first-child {
	border-top: 1px solid #eee;
}
.result-box > p {
	font-weight: 600;
	color: #000;
	font-size: 15px;
	padding: 6px;
	margin-bottom: 7px;
}
.course-result-container::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
}

.course-result-container::-webkit-scrollbar-track {
	box-shadow: none;
	background: transparent;
}

.course-result-container::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	border-radius: 10px;
	outline: none;
}
.course-result-container > span {
	display: block;
	font-size: 15px;
	color: #000;
	font-weight: 600;
	margin-bottom: 10px;
}
.course-result-container > p {
	padding: 7px 20px;
	border-radius: 7px;
	background: #f7f7f7;
	margin-top: 5px;
	overflow: auto;
	display: inline-block;
	font-size: 20px;
	color: #ee9949;
	font-weight: 600;
	border: 1px dashed #dedede;
}

.tips-for-question {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 25px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(20, 23, 28, 0.05);
	font-size: 13px;
	font-weight: 400;
	line-height: 23px;
	margin-top: 21px;
}

.ask-new-question {
	    background: #dd574e;
	box-shadow: 0px 0px 20px rgba(29, 69, 138, 0.3);
	border-radius: 3px;
	padding: 9px 18px;
	color: #fff;
}
.number-of-questions {
	color: #819ba6;
	font-weight: 500;
	font-size: 12px;
	margin-right: 15px;
}
.test-result-tab {
	border-bottom: 1px dashed #c4d9ed !important;
	padding-bottom: 1px;
	padding-left: 15px;
	padding-right: 15px;
}
.test-result-tab .nav-link {
	border: none !important;
	background: #fff;
	border-radius: 6px 6px 0 0;
	box-shadow: 0 0 30px rgb(173 201 224/38%);
	color: #819ba6 !important;
	margin-right: 10px;
	font-weight: 500;
	font-size: 14px;
	padding: 12px 31px;
}
.test-result-tab .nav-link.active {
	color: #ee9949 !important;
}
.webinar-plan-table {
	width: 100%;
	border-collapse: collapse;
	box-shadow: 0 0 7px rgb(223 228 249 / 54%);
	display: table;
}
.webinar-plan-table td,
th {
	border: 1px solid #d5e7f7;
	font-size: 14px;
	text-transform: capitalize;
	color: #819ba6;
	padding: 10px;
}
.webinar-plan-table th {
	background: rgba(220, 234, 249, 0.2);
	font-weight: 500;
	color: #12274b;
}
.webinar-plan-table td .plan-name {
	color: #ee9949;
}
.view-plan-btn {
	font-size: 12px;
	    background: #dd574e;
	border-radius: 5px;
	padding: 5px 10px;
	text-align: center;
	text-transform: capitalize;
	border: none;
	color: #fff;
}
.show-more-btn-center {
	position: relative !important;
	float: none !important;
	margin-top: 10px;
	margin-bottom: 0px;
}

.complete-img{
	width: 30px;
    float: left;
    margin-right: 8px;
    margin-top: 4px;
}
.course-progress{
	background: #fff;
    border: 1px solid #c4d9ed;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 1px;
    width: 80%;
    float: left;
}
.course-progress .progressbar-progress{	    
	height: 3px !important;
	border-radius: 50px;
	background: #5BC716 !important;
}
.course-progress-box{
	overflow: auto;
    display: block;
    margin: 3px 0px 0px;
}
.course-progress-box .progress-percentage{
	font-size: 10px;
    float: left;
    margin-left: 9px;
	margin-top: -2px;
	color: #819BA6;
}



.form-control {
	font-size: 12px !important;
	border-radius: 10px;
	outline: none;
}
.course-result-container > span {
	display: block;
	font-size: 15px;
	color: #000;
	font-weight: 600;
	margin-bottom: 10px;
}
.course-result-container > p {
	padding: 7px 20px;
	border-radius: 7px;
	background: #f7f7f7;
	margin-top: 5px;
	overflow: auto;
	display: inline-block;
	font-size: 20px;
	color: #ee9949;
	font-weight: 600;
	border: 1px dashed #dedede;
}

.tips-for-question {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 25px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(20, 23, 28, 0.05);
	font-size: 13px;
	font-weight: 400;
	line-height: 23px;
	margin-top: 21px;
}

.ask-new-question {
	background: #dd574e;
	box-shadow: 0px 0px 20px rgba(29, 69, 138, 0.3);
	border-radius: 3px;
	padding: 9px 18px;
	color: #fff;
}
.number-of-questions {
	color: #819ba6;
	font-weight: 500;
	font-size: 12px;
	margin-right: 15px;
}
.header-for-pc{
	display: block;
}
.header-for-mobile{
	display: none;
	overflow: hidden;
    box-shadow: 0 0 11px rgb(0 0 0 / 17%);
    background: #fff;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999;
    padding: 6px 7px;
}
.header-for-mobile .logo-for-mobile{
	width: 35px;
    margin-top: 5px;
}
.header-for-mobile .right-sideopen-logo{
	width: 31px;
	margin-top: 7px;
}
.custom-dropedown-for-user{
	position: fixed !important;
    top: 74px !important;
    right: 2px !important;
    float: right;
    left: auto !important;
    transform: none !important;
}
.custom-dropedown-for-user .dropdown-item{
	line-height: 43px;
	margin-left: 0px;
	font-size: 1rem !important;
	color: #212529 !important;
	font-weight: inherit;
}
.custom-dropedown-for-user.my-account-profile{
	top: 51px!important;
}	
.game-section-top-box{
	background: #fff;
    border: 1px solid #d5e7f7;
    box-sizing: border-box;
    box-shadow: 0 0 30px rgb(223 228 249 / 50%);
    border-radius: 7px;
    padding: 0 0 20px;
	overflow: hidden;
	position: relative;
}
.game-question{
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	text-align: center;	
	color: #12274B;
}
.game-section{
	width: 80%;
	margin: 13px auto;
	text-align: center;
}
.game-section .game-answer-letter{
	background: rgba(220,230,235,.1);
    border: 1px solid #d5e7f7;
    box-sizing: border-box;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
	line-height: 30px;
	margin-left: 3px;
	margin-left: 3px;
	color: #FF901D;
    font-weight: 600;

}
.game-answer-block{
	overflow: auto;
    text-align: center;
}
.game-keyboard{
	
    text-align: center;
}
.game-keyboard .game-btn{
	background: #fff;
    box-shadow: 0 4px 10px #dfe4f9;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    margin-left: 5px;
    border: none;
    margin-right: 5px;
    margin-top: 15px;
}
.top-header-lms-menu-tabs{
	padding: 9px 8px;
    border-radius: 6px 6px 0px 0px;
    
}
.game-keyboard .game-btn.success-btn{
	background: #D0F0C2;
	border: 1px solid #5BC716;
	box-sizing: border-box;
	box-shadow: 0px 4px 10px #DFE4F9;	
	color: #3D9C00;
}
.game-keyboard .game-btn.wrong-btn{
	background: #F9CFD2;
	border: 1px solid #EA6F70;
	box-sizing: border-box;
	box-shadow: 0px 4px 10px #DFE4F9;
	color: #E8505B;
}
.game-next-btn{
	background: #ee9949;
    box-shadow: 0 0 20px rgb(238 153 73 / 20%);
    border-radius: 3px;
    border: none;
    padding: 7px 35px;
    color: #fff !important;
	margin: 30px auto 10px;
	width: 170px;
	position: absolute;
	right: 15px;
	bottom: 15px;
}
.game-question-attempt-box{
	background: rgba(220, 230, 235, 0.1);
    border: 1px solid #D5E7F7;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 12px;
	margin-bottom: 10px;
	position: relative;
}
.game-attempt-left{
	position: absolute;
    font-size: 9px;
    left: 0px;
    right: 0px;
    top: 12px;
	color: #000;
	text-align: center;
}
.game-question-attempt-box .progressbar-progress{
    height: 13px !important;
    border-radius: 50px;
}
.right-sideopen-btn{
	display: block;
    float: left;
    height: 45px;
    line-height: 45px;
	padding: 0px 10px 0px 0px;
    font-size: 20px;
	margin-right: 2px;
    position: relative;
    color: #ee9949;
}
.game-open-tagline{
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
    color: #ee9949;
    padding: 5px 20px;
    margin-bottom: -3px;
    margin-top: 27px;
}
.game-open-tagline span{
	color: #12274B;
}
.game-open-tagline-box{
	margin: 20px 20px;
    padding: 17px 34px;
    background: rgba(220,230,235,.1);
    border: 1px solid #d5e7f7;
    box-sizing: border-box;
    border-radius: 7px;
}
.game-open-tagline-box li{
	font-size: 14px;
    line-height: 22px;
    color: #6378AE;
    font-weight: 500;
}
.game-total-score-box{
	display: table;
    width: 100%;
    height: 100%;
}
.game-total-score-inner-box{
	display: table-cell;
    vertical-align: middle;
	text-align: center;
	width: 100%;
    height: 100%;
}
.game-total-score-inner-box p{
	color: #EE9949;
    margin-bottom: 1px;
}	
.game-total-score-inner-box h1{
	color: #12274B;
    font-size: 33px;
    font-weight: 700;
}
.game-total-score{
	background: rgba(220, 230, 235, 0.1);
    border: 1px solid #D5E7F7;
    box-sizing: border-box;
    border-radius: 7px;
    display: inline-block;
    padding: 18px;
}
.mark-as-complete-checkbox{
	line-height: 37px;
    text-align: center;
    color: #819ba6;
    text-transform: capitalize;
    font-weight: 500;
}
.mark-as-complete-checkbox input{
	padding: 11px;
    width: 20px;
    height: 18px;
    border: none!important;
    outline: none;
    background: #524b4b;
    position: relative;
    top: 4px;
}
.complete-unit-name{
	text-align: center;
    font-weight: 500;
    color: #ee9949;
    font-size: 17px;
    margin-bottom: 0px;
    text-transform: uppercase;
}
.custom-dropedown-for-user .dropdown-item:active{
	background-color: #f8f9fa;
}
.total-review {
	font-size: 55px;
    margin-top: 37px;
    font-weight: 400;
    margin-bottom: -8px;
}
.star-rating{
	position: relative;
    height: 31px;
    width: 103px;
    overflow: hidden;
    text-align: center;
    margin: auto;
}
.side {
    float: left;
    width: 5%;
    margin-top: 0px;
    font-size: 10px;
    color: #ababab;
}
.middle {
    margin-top: 5px;
    float: left;
    width: 90%;
}
.right {
    text-align: right;
}
.side {
    float: left;
    width: 5%;
    margin-top: 0px;
    font-size: 10px;
    color: #ababab;
	position: relative;
    top: 6px;
}
.feedbackprogress{
	background: #eee;
    border-radius: 50px;
    overflow: hidden;
}
.feedbackprogress .progressbar-progress{
	background-color: #ee9949 !important;
	height: 15px !important;
}
.report-to-spam .dropdown-toggle::after{
	display: none;
}
.report-to-spam .dropdown-menu{
	margin: 0 !important;
    padding: 0;
    left: -6px !important;
    top: -25px !important;
}
.report-to-spam .dropdown-menu .dropdown-item{
	padding: 5px 0px;
    font-size: 12px;
    text-align: center;
} 
.report-to-spam{
	display: inline-block;
	margin-left: 27px;
}
.elm-btn-primary-blue{
	background: #1D458A!important;
    color: #fff!important;
}

.elm-btn-primary-blue:disabled{
	background: #7b9eda!important;
    color: #fff!important;
}
.go-to-my-account{
	display: block;
    float: right;
    margin-right: 14px;
    background: #dd574e;
    color: #fff !important;
    height: 35px;
    position: absolute;
    right: 15px;
    top: 21px;
    line-height: 35px;
    width: 91px;
    text-align: center;
    box-shadow: 0px 0px 20px rgb(29 69 138 / 30%);
    border-radius: 5px;
    text-transform: uppercase;
}
.unit-description-style{
	text-align: center;
    font-size: 14px;
    color: #ee9949;
    font-weight: 600;
    width: 79%;
    margin: 24px auto 10px;
}
.like-dislike-btn-style a img{
	width: 30px;
    margin-right: 11px;
}
.course-rating-box{
	display: none;
    text-align: center;
    margin-bottom: 17px;
    margin-top: -40px;
}
/* .rate-this-course-text{
	margin: 0px;
	font-size: 14px;
    font-weight: 500;
	color: #12274b;
}
.rate-this-course-text .fa{
	color: #ee9949;
}
.course-rating-box .rate-this-course-text a{
	color: #1d458a!important;
    font-size: 14px;
    font-weight: 500;
}
.course-rating-box .rate-this-course-text a .fa{
    color: #ee9949;
    margin-left: 5px;
    
} */
.rate-this-course-text{
	color: #1d458a;
    font-weight: 500;
    font-size: 12px;
    margin-right: 7px;
    text-decoration: underline;
}
.check_icon_mobile_validation{
	border: 2px solid #ee9949; 
	color: #ee9949; 
	font-size: 25px;  
	width: 50px; 
	height: 50px; 
	line-height: 50px; 
	margin: auto; border-radius: 50px;
}
.mobile_paragraph{
	font-size: 1.3rem;
	color: #ee9949;
}
@media (max-width: 767px) and (min-width: 200px) {
	.top-header-style {
		height: 44px;
		line-height: 44px;
	}
	.elm-logo-e-learn {
		display: none;
	}
	.course-name-top-header a {
		font-size: 13px !important;
	}
	.back-button-e-learn {
		display: block;
	}
	.e-learning-content-section .previous-button {
		display: block;
		height: 43px;
		top: 39%;
		line-height: 43px;
		width: 23px;
		font-size: 21px;
	}
	.e-learning-content-section .next-button {
		display: block;
		height: 43px;
		top: 39%;
		line-height: 43px;
		width: 23px;
		font-size: 21px;
	}
	.course-name-top-header::before {
		height: 100%;
		top: 0px;
	}
	.left-section-for-elearn {
		width: 100%;
	}
	
	.e-learning-dashboard-section {
		margin-top: 0px;
	}
	.lms-controller-nav-bar li a {
		font-size: 14px;
	}
	.only-for-mobile {
		display: inline-block !important;
	}
	
	.section-class{
		width: 100%;
	}
	.right-section-for-elearn{
		position: absolute;
		width: 100%;
		background: #fff;
		top: 0px;
		bottom: 0px;
		height: 100%;
		z-index: 1030;
		transform: translateX(-34.4rem);
		transition: transform .15s cubic-bezier(.2,0,1,.9);
		left: 0;
	}
	.right-section-for-elearn.open-for-mobile-right-menu{
		transform: translateX(0);
		transition: transform .25s cubic-bezier(0,0,.38,.9);
	}
	.side-menu-for-lms{
		height: 100%;
	}
	.top-header-lms-menu{
		text-align: center;
	}
	.top-header-style {
		height: 44px;
		line-height: 44px;
	}
	.elm-logo-e-learn {
		display: none;
	}
	.course-name-top-header a {
		font-size: 13px !important;
	}
	.back-button-e-learn {
		display: block;
	}
	.e-learning-content-section .previous-button {
		display: block;
		height: 42px;
		top: auto;
		bottom: 12px!important;
		line-height: 43px;
		width: 37px;
		font-size: 21px;
	}
	.e-learning-content-section .next-button {
		display: block;
		height: 42px;
		top: auto;
		bottom: 12px!important;
		line-height: 43px;
		width: 37px;
		font-size: 21px;
	}
	.e-learning-content-section .previous-button {
		left: 10%;
	}
	.e-learning-content-section .next-button {
		right: 10%;
	}
	.course-name-top-header::before {
		height: 100%;
		top: 0px;
	}
	.left-section-for-elearn {
		width: 100%;
		overflow: hidden;
	}
	
	.e-learning-dashboard-section {
		margin-top: 0px;
	}
	.lms-controller-nav-bar li a {
		font-size: 14px;
	}
	.only-for-mobile {
		display: inline-block !important;
	}
	.header-for-pc{
		display: none;
	}
	.header-for-mobile{
		display: block;
	}
	.display-none-side-menu-lms .right-section-for-elearn {
		display: block;
	}
	.show-side-menu-lms-btn{
		display: none !important;
	}
	.number-of-questions {
		font-size: 15px;		
		margin-right: 0px;
		display: block;
		text-align: center;
	}
	.ask-new-question {		
		display: block;
		text-align: center;
		margin-top: 17px;
	}
	.lms-dashboard-container {		
		margin: 19px auto 30px;
		padding: 0px;	
	}
	.q-a-section {		
		padding: 25px 0 10px;;		
	}
	.question-date span {
		font-size: 10px;
		padding: 3px;
		display: inline-block;
		border: none !important;
	}
	.user-image-q-and-a {
		width: 35px;		
		height: 35px;
		
	}
	.e-learning-content-section{
		padding: 81px 0px 56px;
	}
	.middle {
		width: 83%;
	}
	.feedbackprogress .progressbar-progress {		
		height: 12px!important;
	}
	.total-review {
		font-size: 49px;
		margin-top: 22px;		
	}
	.report-to-spam .dropdown-menu {	
		margin: 0 !important;
		padding: 0;
		left: -6px !important;
		top: -25px !important;
	}
	.view-plan-btn {		
		background: transparent;		
		color: #dd574e !important;
		display: block;
	}
	.goto-my-account-btn{
		display: inline-block;		
	}
	.unit-name-text{
		display: block;		
		width: 90%;
	}
	.section-class-test{
		width: 100%;
	}
	.course-test-option{
		overflow: auto;
	}
	.course-test-option input{
		float: left;
		display: inline-block;
		width: 6%;
	}
	.course-test-option label{
		width: 88%;
		float: left;
		display: inline-block;
		margin: 0px;
	}
	.test-result-tab{
		display: block;
	}
	.test-result-tab .nav-link{
		display: block;
		padding: 14px 31px;
		border-radius: 0px;
		background: #fbfafa;
		background: #fff;
		box-shadow: 0 0 30px rgb(223 228 249 / 50%);
	}
	.test-result-tab .nav-link.active {
		color: #fff!important;
		background: #ee9949;
	}
	.course-test-option .for-correct-answer {		
		right: 3px;
		top: 13px;
		font-size: 12px;
		
	}
	.webinar-plan-table{
		display: block;
	}
	.go-to-my-account{
		height: 35px;
		right: 5px;
		top: 7px;
		line-height: 35px;
		width: 35px;
		text-align: center;
		border-radius: 50px;
		
	}
	.like-dislike-btn-style a img{
		width: 33px;
		margin-right: 6px;
		margin-left: 8px;
		margin-top: 9px;
	}
	.percentage-of-complete-in-header-img{
		width: 107px;
		margin-top: 6px;
		margin-right: 3px;
	}
	.course-rating-box{
		margin-top: -9px;
		display: block;
		margin-bottom: 10px;
	}
}

@media (max-width: 2560px) and (min-width: 1300px) {
	.section-class {
		height: 400px;
	}
}
.my-btn-primary {
	background-color: #ee9949;
	color: #fff;
	border: none;
	padding: 7px 27px;
}
.my-btn-primary:hover {
	background-color: #f17a0a !important;
}
.form-control {
	font-size: 12px !important;
}

.end-screen-container {
	background: rgba(0, 0, 0, 0.5);
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 90%;
	top: 0;
}
.game-spacer{
	margin-left: .5rem;
}
.game-word-fill{
	background-color: #2c4d92!important;
}
.unit-name-active span.video-time {
    color: #000000!important;
}
body {
  color: #565656;
  font-family: "Poppins", sans-serif;
}

a:hover {
    border: none !important;
    text-decoration: none !important;
}
.form-control:focus, input:focus, textarea:focus {
    box-shadow: 0px 0px 12px rgb(111 110 110 / 9%);
    border-color: #ced4da !important;
}
.form-control::-webkit-input-placeholder, input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{
    color: #ababab;
    font-size: 13px;
}
.form-control:-ms-input-placeholder, input:-ms-input-placeholder, textarea:-ms-input-placeholder{
    color: #ababab;
    font-size: 13px;
}
.form-control::-ms-input-placeholder, input::-ms-input-placeholder, textarea::-ms-input-placeholder{
    color: #ababab;
    font-size: 13px;
}
.form-control::placeholder, input::placeholder, textarea::placeholder{
    color: #ababab;
    font-size: 13px;
}
.display-in-mobile-only{
    display: none;
}
.top-header {
    width: 100%;    
    background: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    height: 73px;
    line-height: 73px;
    margin-bottom: 20px;
    box-shadow: 0 0 20px rgb(170 191 212 / 30%);
}

.elm-logo a{
    display: block;
}
.elm-logo .logo-for-mobile{
    display: none;
}
.top-background{
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 75px;
}
.user-profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    line-height: 0;
    position: absolute;
    padding: 6px;
    background: #FDFDFE;
    left: 0px;
    right: 0px;
    margin: auto;
    top: -67px;
    border: 1px dashed #ADC9E0;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px rgba(223, 228, 249, 0.5);
}

.user-profile-img img {
    border-radius: 50%;
}

.change-profile-picture {
    position: absolute;
    bottom: -2px;
    right: -6px;
    display: block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px dashed #ADC9E0;
    box-sizing: border-box;
    color: #EE9949;
}

.user-name {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    border-bottom: 0 solid #eee;
    padding-bottom: 6px;
    background: #fff;
    padding: 59px 12px 15px 12px;
    color: #1D458A;
    box-shadow: 0 10px 13px -5px rgb(223 228 249 / 55%);
    text-transform: capitalize;

}

.user-name a {
    font-size: 12px;
    color: #000;
    margin-left: 9px;
}

.help-settings {
    position: relative;
    float: right;
}

.help-settings a {
    font-size: 15px;
    margin-left: 7px;
    margin-right: 33px;
    color: #12274b;
}


/* =====end of top header======= */

.main-container {
    width: 90%;
    display: block;
    overflow: auto;
    margin: 25px auto 0px;
    max-width: 1500px;
    min-height: 800px;
}

.my-account-side-menu {
    width: 20%;
    float: left;
    padding: 17px;
    margin-top: 70px;
}

.my-account-right-section {
    width: 80%;
    float: left;
    padding: 17px;
}

.side-menu-account {
    list-style: none;
    padding: 11px;
    margin-top: 10px;
}

.side-menu-account li a {
    color: #12274b!important;
    font-size: 13px;
    display: block;
    height: 45px;
    margin-bottom: 10px;
    line-height: 45px;
    padding: 0 11px;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
}

.side-menu-account li a.active {
    color: #EE9949 !important;
}

.footer {
    background: #696969;
    color: #ffff;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

.section-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #ee9949;
    text-transform: uppercase;
    position: relative;
    height: 80px;
}
.section-header .blue-text{
    color: #2E3552;
}
.section-header p{
    background: #fff;
    display: inline-block;
    position: absolute;
    left: 0px;
    z-index: 9;
    padding-right: 10px;
    margin-bottom: 0px;
    bottom: 24px;
}
.section-header:before{
    content: "";
    height: 1px;
    width: 100%;
    background:#dfeaf3;
    position: absolute;
    left: 0;
    bottom: 37px;
    z-index: 9;
    opacity: .5;
}
.learning-card-left-img {
    position: relative;
    height: 130px;
    max-width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.learning-card-left-img img {
    border-radius: 7px 7px 0 0;
    margin-bottom: 7px;    
}

.learning-card-right-containt {
    padding: 10px 10px 10px 10px;
    position: relative;
}

.custom-learning-card {
    display: block;
    margin-bottom: 15px;
    background: rgba(220, 234, 249, 0.2);
    border: 1px solid #ccddec54;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 10px;
    margin-bottom: 46px;
    position: relative;
}

.learning-name {
    font-size: 12px;
    display: block;
    text-decoration: none;
    font-weight: 500;
    height: 45px;
    overflow: hidden;
    margin-bottom: 30px;
    color: #1D458A !important;
    text-align: center;
    line-height: 19px;
}

.course-name {
    font-size: 11px;
    color: #464646 !important;
}

.unit-time-card {
    margin: auto;
    font-size: 12px;
    background: #dd574e;
    box-shadow: 0 0 20px rgba(29,69,138,.3);
    border-radius: 5px;
    width: 75%;
    text-align: center;
    text-transform: capitalize;    
    margin-top: 10px;
}
.unit-time-card a{
    color: #fff !important;
    display: block;
    padding: 8px;
}
.extension_free{
    background: #1d458a!important;
    color: #fff !important;
    font-weight: 500;
}
.extension_free a{
    color: #fff !important;
    font-weight: 500;
}
.play-overley {
    background: rgb(0 0 0 / 49%);
    position: absolute;
    height: 100%;
    width: 100%;
    display: table;
}

.play-overley span {
    font-size: 52px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.play-overley span a {
    color: #e4e2e2 !important;
}

.show-more-btn {
    border: none;
    font-size: 14px;
    background: transparent;
    float: right;
    text-transform: uppercase;
    color: #12274b;
    font-weight: 500;
    position: absolute;
    right: 15px;
    z-index: 999;
}
.show-more-btn .fa{
    color: #ee9949;
    font-size: 19px;
    position: relative;
    top: 1px;
    right: -3px;
}
.completed-card {
    margin: 5px 0px 3px;
    font-size: 12px;
    font-weight: 600;
    color: #19a200;
}

.custom-progress .progress {
    height: 4px;
    border-radius: 0px !important;
}

.custom-progress {
    margin-bottom: 15px;
}
.right-containt-section{
    background: #fff;
    /* border: 1px dashed #9DB0B9; */
    padding: 18px;
    
    border-radius: 3px;
    box-shadow: 0px 0px 30px rgba(223, 228, 249, 0.5);
    position: relative;
}
.left-panel-my-account{
    background: #FAF9FD;
    /* border: 1px dashed #9DB0B9; */
    margin-bottom: 40px;
    border-radius: 3px;
    box-shadow: 0px 0px 30px rgba(223, 228, 249, 0.5);
    position: relative;
}
.padding-on-top-my-account{
    padding-top: 90px;
    padding-left: 0px;
    padding-right: 0px;
    
}

.learning-card-right-containt .ellipse{
    position: absolute;
    width: 100%;
    top: 62px;
    left: 0px;
    right: 0px;
}
.webinar-date{
    color: #2E3552;
    font-size: 12px;
    text-align: left;
    display: block;
    font-weight: 600;
}
.webinar-date .fa {
    color: #ee9949!important;
    margin-right: 4px;
}
.webinar-time{
    color: #2e3552;
    font-size: 12px;
    text-align: right;
    display: block;
    font-weight: 600;

}
.webinar-time .fa {
    color: #ee9949!important;
    margin-right: 4px;
}
.support-index-page-form .form-control{
    border: 1px solid #CBD0E6 !important;
    box-sizing: border-box;
    border-radius: 6px;
    background: #fff;
    padding: 6px 12px;

}
.support-index-page-form select, .support-index-page-form input{
    height: 40px;
    box-shadow: none !important;
}
.support-index-page-form textarea {
    box-shadow: none !important;
}
.report-submit-btn{
    margin: auto;
    font-size: 14px;
        background: #dd574e;
    box-shadow: 0 0 20px rgba(29,69,138,.3);
    border-radius: 5px;
    padding: 6px 23px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    color: #fff;
}
.report-submit-btn:disabled,
.report-submit-btn[disabled]{
    margin: auto;
    font-size: 14px;
    background: #f0918a;
    box-shadow: 0 0 20px rgba(29,69,138,.3);
    border-radius: 5px;
    padding: 6px 23px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    color: #fff;
    cursor: no-drop;

}
.react-hr-dashed-style{
    border: 1px dashed #bfd6e0 !important;
    margin-top: 43px;
    margin-bottom: 40px;

}
.tickets-hitory-table{
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 0 7px rgb(223 228 249/54%);
    display: table;
}
.tickets-hitory-table th {
    background: rgba(220,234,249,.2);
    font-weight: 500;
    color: #12274b;
}
.tickets-hitory-table td, th {
    border: 1px solid #d5e7f7;
    font-size: 13px;    
    color: #819ba6;
    padding: 10px;
}
.tickets-hitory-table td .tickets-name {
    color: #1D458A;
    font-weight: 600;
}
.view-tickets-details{
    border: none;
    background: transparent !important;
    color: #ee9949 !important;
}
.progress-circle {
    margin: 3px;
    position: relative;
    padding: 0;
    width: 16px;
    height: 16px;
    background-color: #f2e9e1;
    border-radius: 50%;
    line-height: 16px;
 }

 .progress-circle span {
    width: 36px;
    z-index: 2;
    color: #2e3552;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    text-align: center;
    display: block;
    right: -36px;
}

 .progress-circle:after{
    border: none;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #f4f6fc;
    content: " ";
    position: absolute;
 }


 .left-half-clipper {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    clip: rect(0,16px,16px,8px);
 }

 .progress-circle.over50 .left-half-clipper {
    clip: rect(auto,auto,auto,auto);
 }
 .value-bar {
    position: absolute;
    clip: rect(0,8px,16px,0);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid #ee9949;
    box-sizing: border-box;

 }

 .progress-circle.over50 .first50-bar {

    background-color: #ee9949;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    clip: rect(0,16px,16px,8px);
 }
 .progress-circle:not(.over50) .first50-bar{ display: none; }
 .course-review{
    color: #819BA6;
    font-size: 14px;
    display: block;
    text-align: right;
    margin-top: 8px;
}
.course-review .fa{
    color: #EE9949 !important;
    margin-right: 4px;
}
.left-menu-open-btn{
    height: 45px;
    line-height: 45px;
    padding: 0 7px;
    font-size: 20px;
    margin-right: 9px;
    position: absolute;
    color: #ee9949;
    width: 39px;
    display: none;
}
.custom-modal .modal-content{
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    padding: 2px;
}
.custom-modal .modal-header{
    padding: 14.5px 10px;
    margin-bottom: 12px;
    border-radius: 8px 8px 0 0;
    background: #12274b;
    position: relative;
    text-align: center;
    display: block;
}
.custom-modal .modal-header:after {
    content: '';
    width: 100%;
    height: 4px;
    background: linear-gradient(270deg,#bcdefc 0,#ff8d21 50%,#bcdefc 100%);
    position: absolute;
    left: 0;
    bottom: -4px;
}
.custom-modal .modal-header .close{
    position: absolute;
    right: 22px;
    top: 14px;
    width: 35px;
    height: 35px;
    opacity: 1;
    z-index: 999;
}
.custom-modal .modal-header .close span{
    color: #fff;
    font-weight: 100;
}
.custom-modal .modal-header .modal-title{
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
    margin-bottom: 0;
}
.custom-modal .modal-body{    
    box-sizing: border-box;
    border-radius: 7px;
    padding: 15px;
}
.custom-modal .modal-body .modal-topic{
    text-align: center;
    text-transform: uppercase;
    color: #ee9949;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 35px;
    position: relative;
}
.custom-modal .modal-body .modal-topic::after{
    content: "";
    width: 50px;
    height: 4px;
    background: #ee9949;
    position: absolute;
    margin: auto;
    left: 0px;
    right: 0px;
    bottom: -14px;
    border-radius: 50px;
}
.custom-modal .modal-body .modal-topic span{
    color: #12274B;
}
.custom-modal .modal-body .modal-topic-ponits{
    overflow: auto;
    color: #819BA6;
}
.custom-modal .custom_select_dropdown{
    width: 100%;
    height: 46px;
    padding: 0px 10px;
    border-radius: 5px;
    border-color: #BCDEFC;
}
.custom-modal textarea{
    border-color: #BCDEFC !important;
}
.react-stars:focus{
    outline: none !important;
}
.custom-modal .modal-body .form-control{
    border: 1px solid #f3f1f1;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 6px 12px;
    background: #fff;
}
.elm-react-primary-btn{
    background-color: #1D458A;
    color: #fff !important;
}
.cancel-btn{
    color: #819BA6 !important;
    background: transparent;
}
.certificate-top-tab {
	border-bottom: 1px solid #adc9e0 !important;
	padding-bottom: 1px;
	padding-left: 0px;
	padding-right: 15px;
	margin-bottom: 35px;
}
.certificate-top-tab .nav-link {
	border: none !important;
	background: #fff;
	box-shadow: 0 0 30px rgba(223, 228, 249, 0.5);
	border-radius: 7px 7px 0 0;
	color: #12274b !important;
	margin-right: 5px;
	font-weight: 500;
	font-size: 12px;
	padding: 12px 12px;
}
.certificate-top-tab .nav-link.active {
	color: #fff !important;
	background: #ee9949;
	box-shadow: 0px 0px 30px rgba(223, 228, 249, 0.5);
	border-radius: 7px 7px 0px 0px;
}
.elm-certificate-download-btn {
    color: #819ba6!important;
    font-weight: 500;
    font-size: 13px;
    border: none;
    background: transparent;
}
.elm-certificate-download-btn .fa {
	color: #ee9949 !important;
}
.certificate-for-course{
    color: #1d458a;
    text-align: center!important;
    font-weight: 500;
    margin-top: 10px;
    height: 45px;
    overflow: hidden;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 6px;
}
.certificate-progress-bar{
    height: 3px;
    border-radius: 50px;
    width: 90%;
}
.certificate-progress-bar .progress-bar{
    background-color: #EE9949 !important;
}
.certificate-progressbar{
    display: block;
    width: 100%;
    position: relative;
}
.certificate-progress{
    position: absolute;
    right: 0px;
    font-size: 11px;
    top: -7px;
    color: #1D458A;
    font-weight: 600;
}
.progress-bar-text{

    font-weight: 500;
    font-size: 12px;
    text-align: center!important;
    color: #819ba6;
    margin-bottom: 2px;

}
.courese-for-uploade{
    color: #1d458a;
    text-align: left;
    font-weight: 500;
    margin-top: 10px;
    height: 61px;
    overflow: hidden;
    font-size: 14px;
    border-bottom: 1px dashed #9db0b9;
    line-height: 22px;

}
.uploade-docoment-text{
    font-size: 18px;
    font-weight: 600;
    margin: 4px 0px 9px;
    text-transform: uppercase;
    color: #4a4a4a;
}
.uploade-point{
    font-weight: 500;
    font-size: 13px;
    color: #819BA6;
}
.uploade-btn{
    text-align: center;
}
.uploade-btn a{
    color: #EE9949;
}
.elearn-market-btn{
        background: #dd574e;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    color: #fff !important;
}
.same-height{
    height: 425px;
    overflow: hidden;
}
.uploade-docoment-box{
    position: relative;
    max-height: 278px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.uploade-docoment-box::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
background-color: #F5F5F5;
}

.uploade-docoment-box::-webkit-scrollbar
{
width: 6px;
background-color: #F5F5F5;
}

.uploade-docoment-box::-webkit-scrollbar-thumb
{
background-color: #ee9949;
border: none;
}
.bottom-btn{
    position: absolute;
    bottom: 16px;
    width: 100%;
    text-align: center;
}
.top-header-my-account{
    font-size: 22px;
    text-align: right;
    text-transform: uppercase;
    color: #ee9949;
    font-weight: 700;
    position: absolute;
    top: -41px;
    width: 100%;
    right: 13px;
}
.top-header-my-account span{
    color: #12274B;
}
.empty-section-top-box{
    width: 100%;
    height: 300px;
    display: table;
}
.empty-section-top-box .empty-section-inner-box{
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
}
.empty-section-top-box .empty-section-inner-box .empty-page-img{
    width: 85px;
}
.empty-page-text{
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    text-transform: capitalize;
    color: #12274b;
    width: 50%;
    margin: 15px auto;
}
.elm-primary-btn{
        background: #dd574e;
    box-shadow: 0 0 30px rgba(29,69,138,.2);
    border-radius: 7px;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    display: inline-block;
    text-transform: uppercase;
    color: #fff !important;
    text-align: center;
    padding: 5px 35px;
}
.reviw-box-support{
    background: rgba(255, 225, 129, 0.2);
    border-radius: 0px 20px 20px 20px;
    padding: 20px;
    position: relative;
    box-shadow: 0px 0px 2px #dedede;
}
.reviw-box-support .review-name-suport-details{
    color: #1D458A !important;
    font-weight: 500;
}
.reviw-box-support p{
    color: #819BA6;
    font-size: 13px;
    line-height: 25px;
}   
.review-text{
    background: #fff;
    padding: 20px;
}
.reviw-box-support.user-review-support{
    background: rgba(213, 231, 247, 0.25) !important;
    border-radius: 20px 0px 20px 20px;
}
.attachment-in-support-details{
    text-align: right;
    margin-top: 9px;
}
.attachment-in-support-details a{
    text-align: right;
    margin-top: 9px;   
}
.attachment-in-support-details a .fa{
    color: #12274B !important;
}
.support-status{
    text-transform: uppercase;
    color: #1D458A;
    font-size: 13px;
    font-weight: 700;
}
.support-status span{
    color: #819BA6;
}
.support-back-btn{
    padding: 0px;
    background: transparent;
    border: none;
    text-transform: uppercase;
    color: #12274b !important;
}
.support-back-btn .fa{
    color: #ee9949;
    font-size: 18px;
    position: relative;
    top: 1px;
    margin-right: 3px;
}
.profile-topic{
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 5px;
    color: #42555B;
}
.profile-topic-details{
    font-size: 14px;
    color: #819BA6;
}
.elm-btn{   
    box-shadow: 0px 0px 20px rgba(29, 69, 138, 0.3);
    border-radius: 5px;    
    text-transform: uppercase;
    font-size: 14px;
    padding: 10px 35px;
    border: none;
}

.elm-btn-primary{
    background: #CF2D37 !important;
    color: #fff !important;
}
.elm-block-btn{
    display: block;
}
.elm-small-btn{
    font-size: 12px;
    padding: 6px;
}
.delete-account-btn-elm{
    font-size: 14px;
    text-decoration: underline;
    color: #819BA6;
    margin-top: 15p
}
.settings-switch-btn .btn-primary{
    background-color: #ee9949 !important;
    border-color: #ee9949 !important;               
}
.side-menu-account  li a svg{
    width: 18px;
    height: 18px;
    margin-right: 16px;
}
/* .side-menu-account  li a svg path{
    fill: #ee9949!important;
}
.side-menu-account  li a.active img svg path{
    fill: #ee9949!important;
} */

.view-in-older-version a{
    color: #819ba6 !important;
    font-weight: 600;
    text-decoration: underline !important;
    margin-right: 6px; 
    
}
.view-in-older-version{
    position: absolute;
    top: -30px;
    left: 22px;
    z-index: 9;
}
.custom-dropedown-for-user .dropdown-item:active{
	background-color: #f8f9fa;
}
.custom-progress-bar-course{
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 10px;
    margin-top: 3px;
}
.custom-progress-bar-course .progress{
    height: 6px;
    width: 79%;
}
.custom-progress-bar-course .percentage-of-complete{
    position: absolute;
    right: 10px;
    font-size: 9px;
    font-weight: 600;
    top: -4px;
}
.play-btn-for-all-courses .fa{
    font-size: 34px;
    position: relative;
    top: 5px;
    left: 12px;
    color: #ee9949;
}

.dayes-left-expired{
    position: absolute;
    font-size: 9px;
    color: #848181;
    left: 0px;
    bottom: -8px;
}
.custom-modal-filter .modal-content{
    margin: 0px !important;
    max-width: 1000px !important;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    border-radius: 0px;
}
.custom-modal-filter .modal-body{
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    overflow: auto;
    padding: 0px;
}
.custom-modal-filter .modal-body .filter-modal-header{
    padding: 12px;
    overflow: auto;
    border-bottom: 1px dashed #ADC9E0;
}
.custom-modal-filter .modal-body .filter-modal-header span{
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    color: #12274B;
}
.custom-modal-filter .modal-body .filter-modal-header button{
    text-transform: capitalize;
    color: #EF5261;
    border: none;
    background: transparent;
    font-weight: 500;
    font-size: 12px;
}
.filter-modal-body{
    display: flex;
    flex-wrap: wrap;   
    text-align: center;
    height: 90%;
}
.filter-modal-body .left-section{
    background-color: #f1f1f1;    
    flex: 35% 1;

}
.filter-modal-body .left-section ul{
    list-style: none;
    font-size: 12px;
    padding: 0px;
    margin: 0px;
    text-align: left;
}
.filter-modal-body .left-section ul li a{
    display: block;
    height: 44px;
    line-height: 44px;
    padding: 0px 12px;
    text-transform: capitalize;
    color: #1D458A;
    font-weight: 500;
    border-bottom: 1px solid #e4e6e6;
    position: relative;
}
.filter-modal-body .left-section ul li a.active{
    background: #fff;
}
.filter-modal-body .right-section{
    background-color: #fff;   
    flex: 65% 1;

}
.filter-modal-body .right-section ul{
    list-style: none;
    font-size: 12px;
    padding: 0px;
    margin: 0px;
    text-align: left;
}
.filter-modal-body .right-section ul li a{
    display: block;
    height: 44px;
    line-height: 44px;
    padding: 0 29px 0px 12px;
    text-transform: capitalize;
    color: #819BA6;
    position: relative;
    
}
.filter-modal-body .right-section ul li a.active::after{
    content: "\f00c";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    right: 8px;
    top: 17px;
    color: #6db78b;
    font-size: 11px;
}
.custom-modal-filter .modal-footer {
    display: block;
    text-align: center;
    position: absolute;
    width: 100%;
    background: #fff;
    bottom: 0px;
    padding: 0px;
    box-shadow: 0px 0px 20px #e0e0e0;
    border: none;
}
.custom-modal-filter .modal-footer .filter-footer-left{      
    width: 47%;
    display: inline-block;
    float: left;
    color: #819BA6;

}
.custom-modal-filter .modal-footer .filter-footer-right{
    width: 47%;
    display: inline-block;
    float: left;
    color: #EE9949;

}
.custom-modal-filter .modal-footer button{
    border: none;
    background: transparent;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    width: 80%;
    padding: 8px 0px;
}
.open-filter-in-mobile-view{
    display: none;
}
.when-no-webinar-plan{
    box-shadow: 0px 0px 30px rgb(223 228 249 / 50%);
    border-radius: 7px;
    background: #fff;
    padding: 15px;
    overflow: auto;
    border: 1px solid rgba(204,221,236,.33);
}
.when-no-webinar-plan span{
    font-size: 14px;
    line-height: normal;   
    color: #819BA6;

}
.top-header-text-left{
    left: 19px !important;
    right: 0px;
    text-align: left;
}
.host-course-webinar-btn{
    font-size: 14px;
    text-align: right;
    color: #12274b;
    position: absolute;
    top: -36px;
    width: auto;
    right: 22px;
    font-weight: 600;
}
.host-course-webinar-btn a{
    color: #12274b!important;
    margin-left: 20px;
}
.host-course-webinar-btn a .fa{
    color: #ee9949;
    font-size: 13px;
}
.check-preview{
    position: absolute;
    top: -36px;
    width: auto;
    right: 173px;
}
.check-preview a{
    background: #fff;
    border-radius: 3px;
    padding: 7px 18px;
    text-transform: uppercase;
    color: #000;
    border-color: #1d458a;
    font-size: 13px;
}
.send-approval{
    position: absolute;
    top: -36px;
    width: auto;
    right: 13px;
}

.send-approval a{
    background: #1d458a;
    border-radius: 3px;
    padding: 7px 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 13px;
}
.host-course-status-tab{
    font-size: 14px;
    text-align: left;
    color: #12274b;
    position: absolute;
    top: -50px;
    width: auto;
    left: 16px!important;
    right: 0;
    font-weight: 500;
    margin-bottom: 0px;
}
.host-course-status-tab a{
    color: #12274b;
    display: block;
    float: left;
    background: #fff;
    padding: 15px 18px;
    box-shadow: 0 0 30px rgb(223 228 249 / 50%);
    border: 1px dashed transparent;
    font-size: 13px;
}
.host-course-status-tab a:hover{
    border: 1px dashed transparent !important;
}
.host-course-status-tab a.active{
    border: 1px dashed #ADC9E0;
    color: #ee9949 !important;
}
.host-course-status-tab a.active:hover{
    border: 1px dashed #ADC9E0 !important;
}
.host-course-demo-video{
    color: #42555b;
    text-align: right;
    margin-bottom: -9px;
    font-size: 13px;
    font-weight: 500;

}
.host-course-demo-video a{
    border-radius: 4px;
    color: #fff;
    background: #ee9949;
    padding: 5px 14px;
    margin-left: 6px;
}
.delete-promo-video-btn{
    background: #dd574e !important;
    color: #fff !important;
    font-size: 16px;
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 27px;
    text-align: center;
    border-radius: 50px;
    margin-left: 10px;
}
/* ===================================toast========================== */
.custom-toast-for-lms{
    position: fixed;
    bottom: 21px;
    left: 26px;
    z-index: 111;    
}
.custom-toast-for-lms .toast{
    background-color: rgb(64 63 63);
    color: #fff;
    border-radius: 4px;
    font-size: 13px;
    border: none;
}
/* ===================================end of toast========================== */
.select-address-value{
    position: relative;
    background: #f8f9fa;
    padding: 6px 30px 6px 16px;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;    
}
.select-address-value::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    position: absolute;
    right: 9px;
    top: 13px;
}
.list-of-city-state{
    position: absolute;
    width: 66%;
    z-index: 999;
    background: #fff;
    
}
.close-search-profile{
    background: transparent;
    border: none;
    padding: 6px 12px;
}
.list-of-city-state .list-for-city-state{
    overflow-Y: scroll;
    overflow-x: hidden;
    max-height: 283px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 11%);
}

.multiLanguage-dropdown{
    display: inline-block;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #ced4da;
    padding: 4px 9px;
    font-size: 12px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}
.multiLanguage-dropdown input{
    display: none;
}
.multiLanguage-dropdown .form-check{
    padding: 0px;
    cursor: pointer;
}
.multiLanguage-dropdown.active{
    padding-left: 20px;
    background: rgb(99 255 176 / 9%);
}
.multiLanguage-dropdown .form-check-label{
    cursor: pointer; 
}
.multiLanguage-dropdown.active::after{
    content: "\f00c";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    left: 4px;
    top: 8px;
    color: #108f2b;
}
.custom-form-control{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-radius: 10px;
    outline: none;
    font-size: 12px!important;
}
.go-to-my-account-dashboard a{
    background: #dd574e!important;
    color: #fff!important;
    box-shadow: 0 0 20px rgb(29 69 138 / 30%);
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 6px 13px;
    margin-right: 0px;
}
.go-to-my-account-dashboard a .fa{
    margin-left: 10px;
}
.multiLanguage-dropdown{
    display: inline-block;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #ced4da;
    padding: 4px 9px;
    font-size: 12px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}
.multiLanguage-dropdown input{
    display: none;
}
.multiLanguage-dropdown .form-check{
    padding: 0px;
    cursor: pointer;
}
.multiLanguage-dropdown.active{
    padding-left: 20px;
    background: rgb(99 255 176 / 9%);
}
.multiLanguage-dropdown .form-check-label{
    cursor: pointer; 
}
.multiLanguage-dropdown.active::after{
    content: "\f00c";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    left: 4px;
    top: 8px;
    color: #108f2b;
}
.available-coupons {   
    display: block;
    border: 1px solid #c4d9ed!important;
    padding: 16px 12px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;    
}
.available-coupons .available_coupon_name{
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #1D458A;
    font-weight: 600;
    text-transform: capitalize;

}
.available-coupons .available_coupon_details{
    color: #404040;
    display: block;
    width: 100%;
    font-size: 11px;   
}
.available-coupons .available_coupon_details span{
    color: #CF2D37;
    font-weight: 600;
}
.available-coupons .redeem_offer_btn a{
    display: inline-block;
    background:  #CF2D37;
    font-size: 13px;
    color: #fff !important;
    padding: 8px 20px;
    border-radius: 4px;
}
.available-coupons .redeem_offer_btn a .fa{
    color: #EE9949 !important;
    margin-left: 3px;
}
.available-coupon-code {
    background: #FBFDFE;
    padding: 7px 12px;
    color: #12274B;
    font-size: 14px;
    font-weight: 600;
    display: block;
    text-align: center;
    border: 1px solid #D5E7F7;
    border-radius: 100px;
    margin-bottom: 16px;
    position: relative;
}
.available-coupon-code:hover{
    border: 1px solid #D5E7F7 !important;
    color: #12274B !important;
}
.available-coupon-code .copy_icon{
    width: 15px;
    height: 15px;
    display: inline-block;
    position: absolute;
    right: 16px;
    top: 5px;
    color: #12274b!important;
}
.available-coupons .copycode{
    color: #EE9949 !important;
    font-weight: 500;
    margin-left: 8px;
    font-size: 12px;
}
.available-coupons .view_offer_btn{
    text-align: center;
    width: 100%;
    margin: 0px;
}
.available-coupons .view_offer_btn a{
    color: #1d458a!important;
    font-weight: 600;
    font-size: 13px;
}
.available-coupons .view_offer_btn a .fa{
    color: #EE9949 !important;
    margin-left: 3px;
}
.available-coupons .expires_date{
    font-size: 11px;
    font-weight: 500;
    color: #EE9949;
    margin-bottom: 0px;
}
.available-coupons .other_offer_logo{
    width: 108px;
}   
.available-coupon-details {
    color: #1d458a;
    display: block;
    width: 100%;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    height: 57px;
    overflow: hidden;
    margin-bottom: 10px;
}

.checkbox_offer_details{
    font-size: 12px;
}
.checkbox_offer_details input{
    position: relative;
    bottom: -2px;
}
.reported-date{
    font-size: 17px;
    font-weight: 500;
    color: #2e3552;
}
.market-experience{
    border:1px solid #ced4da;
    display: inline-block;
    border-radius: 10px;
    margin-bottom: 0px;
}
.market-experience a{
    padding: 5px 15px;
    color: #000;
    display: inline-block;
    cursor: pointer;
}

.market-experience input{
    width: 60px;
    text-align: center;
    border: none;
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    background-color: transparent;
 }
 .kotak_offer_box{
    display: block;
    border: 1px solid #ADC9E0!important;
    padding: 0;
    border-radius: 8px;
    overflow: auto;
    position: relative;
    margin-bottom: 20px;    
 }   
 .kotak_offer_box .kotak_logo{
    width: 90px;
}
.kotak_offer_box .offer_validity{
    font-size: 38px;
    font-family: 'Merienda One', cursive;
    color: #CF2D37;
    text-align: left;
    margin-top: -30px;
    position: relative;
    overflow: hidden;
}
.kotak_offer_box .offer_validity::after{
    content: '';
    width: 70%;
    height: 1px;
    background: #c5c5c5;
    position: absolute;
    top: 39px;
    left: 88px;    
}
.kotak_offer_box .offer_validity::before{
    content: '';
    width: 70%;
    height: 1px;
    background: #c5c5c5;
    position: absolute;
    top: 42px;
    left: 83px;  
}
.kotak_offer_box .kotak_offer_btn{
    margin-bottom: 0px;
    text-align: right;
}
.kotak_offer_box .kotak_offer_btn a{
    color: #1D458A !important;
    font-weight: 600;
    font-size: 13px;
}
.kotak_offer_box .kotak_offer_btn a .fa{
    color: #EE9949 !important;
    margin-left: 3px;
}
.kotak_offer_box .offer_validity span.month{
    text-transform: capitalize;
    font-size: 18px;
}
.kotak_offer_box .kotak_offer_name{
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #1D458A;
    font-weight: 600;
}
.kotak_offer_box .kotak_offer_details{
    color: #404040;
    display: block;
    width: 100%;
    font-size: 12px;
    height: 37px;
    overflow: hidden;
}
.offer_details_modal .modal-content{
    width: 65%;
    margin: auto;
    box-shadow: 0 0 100px rgb(0 0 0 / 40%);
    border-radius: 20px;
    overflow: hidden;
    border: none;
}
.offer_details_modal .elm_offer_logo{
    width: 130px;
    margin: auto;
}
.offer_details_modal .offer_trems_conditions{
    color: #000;
    font-size: 13px;
}
.offer_details_modal .offer_trems_conditions a{
    color: #CF2D37;
}
.offer_details_modal .offer_name{
    font-size: 26px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 35px;
    color: #12274B;
}
.offer_details_modal  .expires_date{
    color: #cf2d37;
    margin-top: 10px;
    padding-bottom: 23px;
    margin-bottom: -10px;
    font-weight: 500;
    font-size: 14px;
}
.offer_details_modal .bottom_offer_coupon{
    padding: 20px 25px;
}
.bottom_offer_coupon .coupon_code_text{
    background: #fff;
    color: #000;
    font-size: 20px;
    text-transform: uppercase;
    padding: 10px 26px;
    border: 1px dashed #ADC9E0;
    border-radius: 3px;
    margin-right: 10px;
    
}
.available-coupons .other_offer_box_details .offer_description{
    color: #42555B;
    font-weight: 400;
    font-size: 12px;
}
.available-coupons .other_offer_box_details{
    border-left: 1px solid #ADC9E0;
    padding: 10px 0px 10px 20px;
}
.available-coupons .other_offer_box_details .available_coupon_name{
    font-size: 17px;
    margin-top: 0px;
}
.available-coupons .tandc_btn{
    font-weight: 500;
    font-size: 13px; 
    color: #396CE8 !important;
}
.offer_details_modal .coupon_code_text{
    background: #fff;
    border: 1px dashed #819ba6;
    box-shadow: inset 0 5px 20px #fff;
    border-radius: 5px;
    position: relative;
    width: 85%;
    margin: auto;
    padding: 12px 10px;
    margin-bottom: 20px;
}
.offer_details_modal .coupon_code_text span:first-child{
    color: #CF2D37;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}
.offer_details_modal .coupon_code_text .copy_code{
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    right: 9px;
    top: 18px;
    color: #3c79e6;
}
.offer_details_modal .readem_oofer_top_box{   
    padding: 0px 20px 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 10px 20px rgb(173 201 224 / 25%);
} 
.empty-page-sub_text{
    font-size: 14px;
    margin-bottom: 30px;
}
.live_class_wrapper_box{
    height: 80vh;
}
.live_class_wrapper_box .live_class_inner_wrapper_box{
    padding: 40px;
    border: 2px dashed #c4d9ed;
    display: inline-block;
    margin: auto;
    border-radius: 5px;
    margin-top: 70px;
    background: #fff;
}
.live_class_wrapper_box .live_class_inner_wrapper_box .select_your_batch{
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    color: #12274B;
    line-height: 36px;
}
.custom_marquee{
    position: relative;    
    left: 0px;
    right: 0px;
    color: #f00;
    font-weight: 600;
    opacity: .7;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.custom_marquee span{    
    -webkit-animation-name: rightToLeft;    
            animation-name: rightToLeft;
    -webkit-animation-duration: 15s;
            animation-duration: 15s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    display: block;
    position: absolute;
}
.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }
  
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }
  
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }
  
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .popup_tick_bg{
    position: relative;
  }
  .popup_tick_bg .submit_popup_tick {
    width: 98px;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    top: 35px;
}
.submit_header{
    font-weight: 500;
    font-size: 26px;
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;
    color: #000;
}
.submit_header span {
    font-weight: 700;
    color: #EE9949;
}
@-webkit-keyframes rightToLeft {
    0% {
      transform: translateX(100%);
      top: 11px;
      width: 100%;
    }
    20% {
      transform: translateX(-100%);
      top: 11px;
      width: 100%;
    }
    60% {
        transform: translateX(-100%);
        bottom:20px;  
        top: auto;  
        opacity: 0;   
        width: 100%; 
    }
    80% {
        transform: translateX(100%);
        bottom:20px;
        top: auto; 
        opacity: 1;
        width: 100%;
    }

    100% {
        transform: translateX(100%);
        top: 11px;
        width: 100%;
    }
  }
@keyframes rightToLeft {
    0% {
      transform: translateX(100%);
      top: 11px;
      width: 100%;
    }
    20% {
      transform: translateX(-100%);
      top: 11px;
      width: 100%;
    }
    60% {
        transform: translateX(-100%);
        bottom:20px;  
        top: auto;  
        opacity: 0;   
        width: 100%; 
    }
    80% {
        transform: translateX(100%);
        bottom:20px;
        top: auto; 
        opacity: 1;
        width: 100%;
    }

    100% {
        transform: translateX(100%);
        top: 11px;
        width: 100%;
    }
  }
  .webinar_plan_offer {
    background: #fff6f0;
    border-radius: 20px;
    border: 1px solid #ffc996;
    padding: 9px 22px;
    margin-top: 34px;
    box-shadow: 0 10px 30px #dfe4f9;
}
.webinar_plan_offer .webinar_plan_offer_text {
    color: #12274b;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 21px;
}
.webinar_plan_offer_img {
    background: #ffe8d9;
    display: block;
    border-radius: 100px;
    width: 79%;
    padding: 9px;
    margin: auto;
}
.webinat_plan_btn {
    font-weight: 600;
    text-transform: capitalize;
    color: #1d458a!important;
    border: none;
    position: relative;
    font-size: 16px;
    text-decoration: underline!important;
    display: block;
    text-align: center;
}
.webinat_plan_btn svg{
    margin-left: 4px;
}
@media(max-width: 766px) and (min-width: 200px) {
    .display-in-mobile-only{
        display: block;
    }
    .help-settings .course-webinar-link-top a{
        display: none;
    }
    .user-profile-img {
        bottom: -4px;
        float: none;
        top: 11px;
        margin: 0px 0px;
        width: 70px;
        height: 70px;
        left: 13px;
    }
    .user-name {
        color: #000;
        margin-bottom: 36px;
        margin-top: 0px;
        font-weight: 500;
        padding: 0px 10px 0px 99px;
        text-align: left;
        font-size: 14px;
        height: 95px;
        line-height: 95px;
        text-transform: capitalize;
        
    }
    .author-and-student-btn {
        position: absolute;
        width: 100%;
        left: 0px;
    }
    .author-and-student-btn a{
        text-align: center !important;
        line-height: 20px !important;
    }
    .top-header {       
        overflow: hidden;
        box-shadow: 0 0 11px rgb(0 0 0 / 17%);
        background: #fff;        
        top: 0;
        width: 100%;
        z-index: 999;
        padding: 6px 7px;
        height: auto;
        line-height: inherit;
        position: fixed;
    }

    .elm-logo {
        width: 47px;
        position: relative;
        left: 49px;
        top: 0px;
    }
    .left-menu-open-btn{
        display: block;
    }
    .left-panel-my-account{
        height: 100%;
        box-shadow: 0 0 30px rgb(131 132 138 / 66%);
        border: none;
    }
    .left-menu-mobile{
        display: block;
        position: fixed;
        top: 0;
        width: 28rem;
        max-width: 82%;
        height: 100%;
        background: #fff;
        z-index: 1030;
        transform: translateX(-34.4rem);
        transition: transform 150ms cubic-bezier(.2,0,1,.9);
        left: 0;
    }
    .open-left-menu{
        transform: translateX(0);
        transition: transform 250ms cubic-bezier(0,0,.38,.9);
    }
    .left-menu-overley{
        background: rgba(30,30,28,.8);
        border-radius: 0;
        left: 0;
        opacity: 0;
        transform: scale(0);
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        transition: opacity 100ms linear,transform 0ms linear 100ms;
        z-index: 1030;
        margin: 0;
    }
    .show-overley{
        opacity: 1;
        transform: scale(1);
        transition: opacity 100ms linear;
    }
    .view-in-older-version {
        top: -79px;
        left: 0px;
        width: 100%;
        right: 0px;
        text-align: center;
    }
    .elm-logo .lms-elm-icon{
        display: none;
    }
    .elm-logo .logo-for-mobile{
        display: block;
    }
    .top-header .row .col-8{
        box-shadow: none !important;
    }
    .padding-on-top-my-account {
        padding-top: 162px;
    }
    .open-filter-in-mobile-view{
        display: block;
        position: fixed;
        background: #fff;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 99;
        height: 41px;
        line-height: 41px;
        text-align: center;
        box-shadow: 0px 0px 20px #e0e0e0;
        color: #12274b;
        text-transform: uppercase;

    }
    .open-filter-in-mobile-view .fa{
        color: #fd9601;
        margin-right: 4px;
    }
    .certificate-top-tab {        
        padding-right: 0px;
        margin-bottom: 35px;
        display: block;
    }
    .certificate-top-tab .nav-link{
        margin-right: 0px;
    }
    .certificate-top-tab .nav-link, .certificate-top-tab .nav-link.active{
        border-radius: 0px;
    }
    .top-header-my-account{
        right: 0px;
        text-align: center;
    }
    .tickets-hitory-table{
        display: block;
    }
    .when-no-webinar-plan span {
        font-size: 12px;
    }
    .go-to-my-account-dashboard a span{
        display: none;
    }
    .go-to-my-account-dashboard a {
        font-size: 18px;
        padding: 0;
        margin-right: 5px;
        height: 35px;
        width: 35px;
        line-height: 35px;
        display: block;
        text-align: center;
        border-radius: 50px;
        position: relative;
        top: 6px;
    }
    .go-to-my-account-dashboard a .fa{
        margin: 0px;
    }
    .select-address-value{
        display: block;
    }
    .available-coupon-details{
        height: auto;
        min-height: 55px;
        overflow: visible;
    }
    .available-coupons{
        height: auto;
    }
    .available-coupons .view_offer_btn{
        position: relative;
    }
    .kotak_offer_box .kotak_offer_details{
        height: auto;
    }
    .offer_details_modal .modal-content{
        width: 100%;
    }
    .offer_details_modal .elm-primary-btn{
        margin-top: 0px;
    }
    .available-coupons .other_offer_box_details{
        padding: 0px !important;
        border: none !important;
    }
    .available-coupons .other_offer_logo {       
        margin-top: 20px;
        margin-bottom: 22px;
    }
    .available-coupons .other_offer_box_details .available_coupon_name{
        font-size: 16px;      
        margin-bottom: 10px;
    }
    .available-coupons .expires_date{
        font-size: 11px;        
        text-align: left;
        margin-top: 16px;
    }
    .offer_details_modal .coupon_code_text{
        width: 100%;
    }
    .empty-section-inner-box .elm-btn{
        display: block;
        margin-right: 0px !important;
        margin-bottom: 20px;
    }
    .empty-page-text{
        width: 100%;
    }
    .live_class_wrapper_box .live_class_inner_wrapper_box{
        padding: 20px;
        margin: 70px 20px;
    }
    .live_class_wrapper_box .live_class_inner_wrapper_box .select_your_batch {        
        font-size: 15px;      
        line-height: 27px;
    }
    .webinar_plan_offer_img{
        width: 100px;
        height: 100px;
    }
}
.mytooltip{
    font-size: 10px!important;
    padding: 3px 5px!important;
}
.vjs-poster{
    background-size: auto;
}
.footer-headding {
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    text-transform: uppercase;
    color: #12274B;
    position: relative;
    margin-bottom: 27px;
}
.footer-headding::after {
    content: '';
    background: #ee9949;
    height: 3px;
    width: 42px;
    position: absolute;
    left: 0px;
    bottom: -8px;
    border-radius: 50px;
}
.footer-menu {
    margin: 0px;
    padding: 0px;
}
.footer-menu li {
    list-style: none;
    margin-bottom: 4px;
}
.footer-menu li a {
    font-size: 12px;
    color: #819BA6;
}
.footer-text {
    font-size: 12px;
    color: #819BA6;
    margin-bottom: 8px;
}
.text-left-pc {
    text-align: left;
}
.text-right-pc {
    text-align: right;
}
.footer-menu li span {
    font-size: 12px;
    color: #819BA6;
}
.footer-image-container img{
    margin-right: 5px;
}
.topic-heading-style-index{
    text-transform: uppercase;
    color: #EE9949;
    font-weight: 700;
    font-size: 30px;
    position: relative;
    margin-top: 20px;
}
.topic-heading-style-index span{
    color: #12274B;
}
.topic-sub-heading-style-index{
    font-size: 19px;
    margin-top: 20px;
    margin-bottom: 30px;
    position: relative;
}
.topic-sub-heading-style-index::after {
    content: '';
    background: #ee9949;
    height: 4px;
    width: 71px;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;    
    bottom: -8px;
    border-radius: 50px;
}
.custom-card{
    border: 1px solid #dee4e8;
    box-shadow: 0px 10px 30px rgb(223 228 249 / 80%);
    margin-bottom: 40px;
}
.host-course-card .course-type{
    color: #1D458A;
    font-weight: 500;
    font-size: 10px;
}
.host-course-card .course-type .fa{
    color: #fe9700 !important;
}
.host-course-card .learning-card-right-containt{
    padding: 0px;
}
.host-course-card .learning-card-right-containt .learning-name{
    text-align: left;
    color: #12274B;
    margin-bottom: 5px;
    color: #12274b !important;
}
.host-course-price-section{
    padding-top: 8px;
    padding-bottom: 8px;
    background: #fff;
    border: 1px solid rgba(204,221,236,.33);
    margin-left: -11px;
    margin-right: -11px;
    margin-top: 8px;
}
.host-course-price-section .course-price-section{
    font-size: 11px;
    color: #819BA6;
    font-weight: 400;
}
.host-course-price-section .course-price-section .fa{
    font-size: 10px;
}
.host-course-card .host-course-bottom-btn-section{
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
}
.host-course-card .host-course-bottom-btn-section li{
    flex: auto;
    text-align: center;
    border-right: 1px solid rgba(204,221,236,.33);
}
.host-course-card .host-course-bottom-btn-section li a{
    color: #1d458a!important;
    font-weight: 500;
    font-size: 11px;
    display: block;
    padding: 11px 0;
}
.host-course-card .host-course-bottom-btn-section li:last-child{
    border-right: none;
}
.add-course-form-label{
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
    margin-top: 20px;
    font-size: 15px
}
.add-course-form-label .fa{
    color: #ee9949;
    margin-left: 3px;
    font-size: 12px;
}
.form-box-options{
    border: 1px solid #cbd0e6;
    display: flex;
    border-radius: 8px;
}
.form-box-options .form-check{
    flex: auto;
    padding: 4px 16px;
    margin: 0px;
}
.tox-editor-container .tox-toolbar__primary{
    background: #eee !important;
    border-bottom: 1px solid #ccc;
}
.host-section-header{
    background: #2E5DAD;
    color: #fff;
    padding: 12px 0px;
    border-radius: 8px 8px 0px 0px;
    margin-top: 12px;
    box-shadow: 0px 0px 5px rgb(122 171 214 / 63%);
}
.open-host-section-header{
    background: #174490;
    width: 20px;
    display: block;
    float: right;
    text-align: center;
    height: 20px;
    border-radius: 50px;
    line-height: 20px;
}
.host-batch-section-header{
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 12px rgb(165 172 177 / 18%);
}
.inner-host-section-header{
    background: rgb(220 234 249 / 42%);
    padding: 14px;
    margin-bottom: 5px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #D5E7F7;
}
/* .inner-host-section-header:hover{
    border: 1px solid rgba(204,221,236,.33) !important;
} */
.inner-chapter-section-header{
    border: none;
    background: transparent;
    padding: 0px 4px;
    color: #1D458A;
    font-weight: 600;
    font-size: 13px;
    display: block;
    overflow: auto;
}
.inner-unit-section-header{
    color: #ee9949!important;
    margin-top: 14px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid #D5E7F7 !important;
}
.inner-unit-section-header:hover{
    border: 1px solid #D5E7F7 !important;
}
.host-chapter-header{
    border: 1px solid rgba(204,221,236,.33) !important;
    background: #fff;
    padding: 5px 0px 10px 0px;
    box-shadow: 0px 0px 7px rgb(122 171 214 / 18%);
}
.open-host-chapter-header{    
    background: #d3e4f5;
    width: 20px;
    display: block;
    float: right;
    text-align: center;
    height: 20px;
    border-radius: 50px;
    line-height: 20px;
    color: #446bb9;
}
.manage-chapter-box{
    background: rgb(249 249 249);
    padding: 7px 0 7px;
    text-align: left;
    border: 1px solid rgba(204,221,236,.33)!important;
    font-size: 12px;
    color: #12274b;
    font-weight: 500;
    box-shadow: none;
}
.manage-chapter-box{
    font-size: 12px;
    color: #12274b;
    font-weight: 500;
}
.manage-chapter-box .fa{
    color: #ee9949;
    margin-left: 9px;
}
.batch-date-in-host-batch{
    color: #ee9949;
    margin-bottom: 0;
    font-size: 13px;
    padding: 0 4px;
    margin-top: 7px;
}
.add-session-btn{
    color: #12274b;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 14px;
    margin-right: 6px;

}
.add-session-btn .fa{
    color: #ee9949;
    margin-left: 9px;
}
.dashboard-top-section p{
    font-size: 17px;
    color: #12274b;
    text-transform: uppercase;
    font-weight: 600;
}
.dashboard-top-inner-section{
    position: relative;
    overflow: hidden;
    padding: 23px 10px;
    margin-bottom: 17px;
    padding-bottom: 15px;
    margin-top: 19px;
}
.dashboard-top-inner-section::after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 50%;
    height: 220px;
    box-shadow: inset 0 0 30px rgb(208 216 249);

}
.dashboard-top-inner-section h2{
    color: #1D458A;
    font-size: 28px;
    margin-top: 12px;
    font-weight: 700;
    margin-bottom: 1px;
}
.dashboard-top-inner-section span{
    color: #ee9949;
    display: block;
    font-size: 12px;
    font-weight: 500;
}
.host-webinar-card-btn{
    color: #dd574e!important;
    font-weight: 500;
    text-align: center;
    display: block;
    padding: 6px 0px;
    background: transparent;
    border: none;
}
.host-webinar-card-btn:hover{
    background: transparent !important;
    border: none !important;
}
.tab-for-host-unit-section{
    padding: 27px 15px;
    border: 1px solid #cbd0e6!important;
    margin-bottom: 27px;
    background: #f8fbfe;
    border-radius: 0px 0px 8px 8px !important;
}
.author-section-btn{
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    padding: 10px 35px;
    cursor: pointer;
    transition: all .6s;
    border: none;
    display: inline-block;
}
.author-red-btn{
    background: #dd574e !important;
    color: #fff !important;
}
.author-orange-btn{
    background: #EE9949 !important;
    color: #fff !important;
}
.author-blue-btn{
    background: #1D458A !important;
    color: #fff !important;
}
.author-red-btn.only-text-color{
    background-color: transparent !important;
    color: #dd574e !important;
    font-weight: 600;
}
.author-cancel-btn{    
    color: #819BA6 !important;
}
.author-cancel-btn.only-text-color{
    background-color: transparent !important;
    color: #819BA6 !important;
}
.author-modal-header{
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    color: #12274B;
    font-size: 22px;
    position: relative;
}
.author-modal-header::after{
    content: '';
    background: #ee9949;
    height: 3px;
    width: 40px;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    bottom: -11px;
    border-radius: 50px;
}
.author-modal-header-none{
    padding: 0;
    text-align: right;
    display: block;
    border: none;
}
.author-modal-header-none .close{
    margin: 7px 15px;
    padding: 0px;
}
.host-section-units{
    background: #ffffff!important;
    box-shadow: none;   
    border: 1px solid #eee;
    color: #12274b;  
}
.host-section-units p{
    color: #446bb9 !important;
    font-weight: 600;
}
.create-game-dropdown span{
    border: 1px solid #cbd0e6!important;
    padding: 10px 48px 10px 31px;
    background: #fff;
    display: inline-block;
    border-radius: 6px;
    position: relative;
    margin-bottom: 0px;
    margin-top: 11px;
}
.create-game-dropdown .fa{
    position: absolute;
    right: 12px;
}
.custom-breadcrumb-author .breadcrumb{
    background: transparent;
    padding: 0px;
    font-size: 11px;
    margin-bottom: 0px;
}
.custom-breadcrumb-author .breadcrumb .breadcrumb-item a{
    color: #819BA6;
}
.custom-breadcrumb-author .breadcrumb .breadcrumb-item.active{
    color: #ee9949!important;
}
.upload-video-unit{
    position: fixed;
    right: 1%;        
    bottom: 2%;
    z-index: 9999;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #eee;
    min-width: 350px;
}
.upload-video-unit .close{
    position: absolute;
    text-shadow: none;
    opacity: 1;
    background: #eee;
    width: 18px;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #000;
    border-radius: 50%;
    top: 2px;
    right: 8px;
    z-index: 999;
}
.upload-video-unit .upload-video-unit-header{
    background: #3c3c3c;
    color: #fff;
    margin-bottom: 0px;
    padding: 10px;
    font-size: 11px;
}
.upload-video-unit .upload-video-unit-details{
    padding: 15px;
    color: #819BA6;
    background: #fff;
    font-size: 13px;
}
.author-and-student-btn{
    margin-bottom: 0px;
    display: flex;
    margin-top: 8px;
}
.author-and-student-btn a{
    font-size: 11px!important;
    flex: auto;
    background: #dfe4f9;
    padding: 6px 6px;
    margin: 4px;
    color: #12274b;
    border-radius: 4px;
    text-decoration: none;
}
.author-and-student-btn a.active{
    background:#ee9949!important;
    color: #fff;
}
.view-summary-report-btn{
    box-shadow: 0 10px 13px -5px rgb(223 228 249 / 42%);
    text-align: center;
    padding-bottom: 35px;
    margin-bottom: 49px;
    margin-top: 30px;
    margin-left: -18px;
    margin-right: -18px;
}
.view-summary-report-btn a{
    background: #1d458a;
    color: #fff!important;
    text-transform: uppercase;
    font-size: 15px;
    padding: 10px 22px;
    display: inline-block;
    border-radius: 4px;
}
.view-btn-course-deiscussion{
    color: rgb(255 255 255);
    font-weight: 500;
    font-size: 10px;
    background: #1d458a;
    padding: 4px 14px;
    border-radius: 4px;
}
.view-btn-course-deiscussion a{
    color: #fff;
}
.upload-course-img-btn-box{
    display: table;
    height: 100%;
    width: 100%;
}
.upload-course-img-btn-inner-box{
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: center;
}
.upload-image-container{
    height: 270px;
    width: 450px;
}
.upload-image-container img{
    width: 100%;
}
.host-section-header .drag-icon svg{
    width: 17px;
    height: 17px;
    margin-right: 4px;
}
.inner-host-section-header .drag-icon svg{
    width: 18px;
    height: 18px;
    margin-right: 6px;
}
.inner-unit-section-header .drag-icon svg{
    width: 15px;
    height: 15px;
    margin-right: 6px;
}
.inner-host-section-header .drag-icon svg path, .inner-unit-section-header .drag-icon svg path{
    fill: #5d8fb9 !important;
}
.section-header.for-edit-course-details-page{
    height: 38px;
}
.section-header.for-edit-course-details-page p{
    bottom: 0px;
}
.section-header.for-edit-course-details-page:before{
    bottom: 13px;
}
.add-section-btn-box{
    background: rgba(213,231,247,.2);
    border: 1px solid #d5e7f7;
    border-radius: 7px;
    text-align: center;
    padding: 40px 0px;
    margin-top: 49px;
    margin-bottom: 50px;
}
.author-report-top-header{
    margin-bottom: 12px;
    margin-top: 38px;
    color: #1d458a;
    font-weight: 600;
    font-size: 16px;
}
.upload-course-video-name-unit{
    background: #e7f2fd;
    padding: 10px;
    border-radius: 4px;
}
.dashboard-current-month-text{
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-weight: 600;
    color: #ee9949;
    position: relative;
}
.dashboard-current-month-text::after{
    content: "";
    background: #1d458a;
    height: 4px;
    width: 42px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -8px;
    border-radius: 50px;
}
.upload-course-video-name-unit .close-btn {
    border: none;
    background: transparent;
    color: #dd574e;
    font-size: 16px;
}
